import flagUs from "assets/images/flags/20/us.svg";
import flagIn from "assets/images/flags/20/in.svg";
import flagspain from "assets/images/flags/20/es.svg";
import flaggermany from "assets/images/flags/20/de.svg";
import flagfrench from "assets/images/flags/20/fr.svg";
import flagjapan from "assets/images/flags/20/jp.svg";
import flagchina from "assets/images/flags/20/china.svg";
import flagitaly from "assets/images/flags/20/it.svg";
import flagrussia from "assets/images/flags/20/ru.svg";
import flagarabic from "assets/images/flags/20/ae.svg";


const languages = {
  in: {
    label: "हिंदी",
    flag: flagIn,
  },
  en: {
    label: "English",
    flag: flagIn,
  },
  
}

export default languages
