// Define the AirRaidInputs interface
export interface AirRaidInputs {
  number_of_aircraft: number;
  aircraft_accuracy: number;
  square_kilometers_covered_per_attack: number;
  total_number_of_armored_vehicles: number;
  width: number;
  height: number;
  k_kill_ratio: number;
  m_kill_ratio: number;
  ad_boost_factor: number;
  lat: number;
  lon: number;
}

// Define the SurvivabilityResult interface
export interface SurvivabilityResult {
  overallSurvivability: number;
  kKill: number;
  mKill: number;
  survivabilityWithAD: number;
  kKillWithAD: number;
  mKillWithAD: number;
  calculationExplanation: string;
  keyInsights: string;
}

// Implement the TankSurvivabilityCalculator class
export class TankSurvivabilityCal {
  private inputs: AirRaidInputs;

  constructor(inputs: AirRaidInputs) {
    this.inputs = inputs;
  }

  public calculateSurvivabilityWithDensity(): SurvivabilityResult {
    const { number_of_aircraft, aircraft_accuracy, square_kilometers_covered_per_attack, total_number_of_armored_vehicles, width, height, k_kill_ratio, m_kill_ratio, ad_boost_factor } = this.inputs;
    

   let mkill_ration=k_kill_ratio;
  let kkill_ration=m_kill_ratio;

  console.log("mkill_ration",mkill_ration);
  console.log("kkill_ration",kkill_ration);

    const total_deployment_area_in_square_km = width * height;
    const total_sq_km_covered_by_attack_aircrafts = number_of_aircraft * square_kilometers_covered_per_attack;
    const tank_density = total_number_of_armored_vehicles / total_deployment_area_in_square_km;
    const aircraft_density = number_of_aircraft / total_deployment_area_in_square_km;
    const aircraft_density_accurate = aircraft_density * aircraft_accuracy / 100;
    const total_number_of_tanks_in_attack_area = Math.min(tank_density * total_sq_km_covered_by_attack_aircrafts, total_number_of_armored_vehicles);
    const number_of_tanks_affected_by_attack = total_number_of_tanks_in_attack_area * aircraft_density_accurate;
    const number_of_tanks_killed_by_attack_no_ad = number_of_tanks_affected_by_attack * (kkill_ration / 100);
    const k_kill_without_ad = (number_of_tanks_killed_by_attack_no_ad / total_number_of_armored_vehicles) * 100;
    const number_of_tanks_mobility_impacted_by_attack_no_ad = number_of_tanks_affected_by_attack * (mkill_ration / 100);
    const m_kill_without_ad = (number_of_tanks_mobility_impacted_by_attack_no_ad / total_number_of_armored_vehicles) * 100;
    const tank_survivability_no_ad = 100 - k_kill_without_ad;
    const number_of_aircraft_killed_by_ad = number_of_aircraft * (ad_boost_factor / 100);
    const number_of_aircraft_survived = number_of_aircraft - number_of_aircraft_killed_by_ad;
    const total_sq_km_covered_by_attack_aircrafts_survived = number_of_aircraft_survived * square_kilometers_covered_per_attack;
    const total_number_of_tanks_in_attack_area_aircrafts_survived = Math.min(tank_density * total_sq_km_covered_by_attack_aircrafts_survived, total_number_of_armored_vehicles);
    const number_of_tanks_affected_by_attack_aircrafts_survived = total_number_of_tanks_in_attack_area_aircrafts_survived * (aircraft_accuracy / 100);
    const number_of_tanks_killed_by_attack_aircrafts_survived = number_of_tanks_affected_by_attack_aircrafts_survived * (kkill_ration / 100);
    const number_of_tanks_mobility_impacted_by_attack_aircrafts_survived = number_of_tanks_affected_by_attack_aircrafts_survived * (mkill_ration / 100);
    const tank_survivability_with_ad = 100 - (number_of_tanks_killed_by_attack_aircrafts_survived / total_number_of_armored_vehicles) * 100;

    return {
      overallSurvivability: tank_survivability_no_ad,
      kKill: k_kill_without_ad,
      mKill: m_kill_without_ad,
      survivabilityWithAD: tank_survivability_with_ad,
      kKillWithAD: (number_of_tanks_killed_by_attack_aircrafts_survived / total_number_of_armored_vehicles) * 100,
      mKillWithAD: (number_of_tanks_mobility_impacted_by_attack_aircrafts_survived / total_number_of_armored_vehicles) * 100,
      calculationExplanation: this._get_calculation_explanation(
        total_deployment_area_in_square_km,
        total_sq_km_covered_by_attack_aircrafts,
        tank_density,
        aircraft_density,
        total_number_of_tanks_in_attack_area,
        number_of_tanks_affected_by_attack,
        number_of_tanks_killed_by_attack_no_ad,
        k_kill_without_ad,
        number_of_tanks_mobility_impacted_by_attack_no_ad,
        m_kill_without_ad,
        tank_survivability_no_ad,
        number_of_aircraft_killed_by_ad,
        number_of_aircraft_survived,
        total_sq_km_covered_by_attack_aircrafts_survived,
        total_number_of_tanks_in_attack_area_aircrafts_survived,
        number_of_tanks_affected_by_attack_aircrafts_survived,
        number_of_tanks_killed_by_attack_aircrafts_survived,
        number_of_tanks_mobility_impacted_by_attack_aircrafts_survived,
        tank_survivability_with_ad
      ),
      keyInsights: this._get_key_insights(
        k_kill_without_ad,
        m_kill_without_ad,
        tank_survivability_no_ad,
        tank_survivability_with_ad
      )
    };
  }

  private _get_calculation_explanation(
    total_deployment_area_in_square_km: number,
    total_sq_km_covered_by_attack_aircrafts: number,
    tank_density: number,
    aircraft_density: number,
    total_number_of_tanks_in_attack_area: number,
    number_of_tanks_affected_by_attack: number,
    number_of_tanks_killed_by_attack_no_ad: number,
    k_kill_without_ad: number,
    number_of_tanks_mobility_impacted_by_attack_no_ad: number,
    m_kill_without_ad: number,
    tank_survivability_no_ad: number,
    number_of_aircraft_killed_by_ad: number,
    number_of_aircraft_survived: number,
    total_sq_km_covered_by_attack_aircrafts_survived: number,
    total_number_of_tanks_in_attack_area_aircrafts_survived: number,
    number_of_tanks_affected_by_attack_aircrafts_survived: number,
    number_of_tanks_killed_by_attack_aircrafts_survived: number,
    number_of_tanks_mobility_impacted_by_attack_aircrafts_survived: number,
    tank_survivability_with_ad: number
  ): string {
    return (
      `Total deployment area (in square kilometers): ${total_deployment_area_in_square_km}\n` +
      `Total square kilometers covered by attack aircraft: ${total_sq_km_covered_by_attack_aircrafts}\n` +
      `Tank density (tanks per square kilometer): ${tank_density}\n` +
      `Aircraft density (square kilometers covered per aircraft): ${aircraft_density}\n` +
      `Total number of tanks in the attack area: ${total_number_of_tanks_in_attack_area}\n` +
   //   `Number of tanks affected by the attack: ${number_of_tanks_affected_by_attack}\n` +
   
      `Number of tanks with mobility impacted (M-kill) without AD: ${number_of_tanks_mobility_impacted_by_attack_no_ad}\n` +
      `Percentage of tanks with mobility impacted without AD (M-kill %): ${m_kill_without_ad}\n\n\n` +

      `Number of tanks totally destroyed (K-kill) without AD: ${number_of_tanks_killed_by_attack_no_ad}\n` +
      `Percentage of tanks totally destroyed without AD (K-kill %): ${k_kill_without_ad}\n\n` +

      `Overall tank survivability without AD (%): ${tank_survivability_no_ad}\n` +
      `Number of aircraft killed by AD: ${number_of_aircraft_killed_by_ad}\n\n\n` +
      `Number of aircraft survived: ${number_of_aircraft_survived}\n` +
      `Total square kilometers covered by surviving attack aircraft: ${total_sq_km_covered_by_attack_aircrafts_survived}\n` +
      `Total number of tanks in the attack area by surviving aircraft: ${total_number_of_tanks_in_attack_area_aircrafts_survived}\n` +
      `Number of tanks affected by the attack by surviving aircraft: ${number_of_tanks_affected_by_attack_aircrafts_survived}\n` +
      `Number of tanks with MKILL impacted by the attack by surviving aircraft: ${number_of_tanks_mobility_impacted_by_attack_aircrafts_survived}\n` +
      `Number of tanks killed by the attack by surviving aircraft: ${number_of_tanks_killed_by_attack_aircrafts_survived}\n` +
      
      `Tank survivability with AD (%): ${tank_survivability_with_ad}\n`
    );
  }

  private _get_key_insights(
    k_kill_without_ad: number,
    m_kill_without_ad: number,
    tank_survivability_no_ad: number,
    tank_survivability_with_ad: number
  ): string {
    return (
      `Key Insights:\n` +
      `1. Percentage of tanks totally destroyed without air defense: ${k_kill_without_ad.toFixed(2)}%\n` +
      `2. Percentage of tanks with mobility impacted without air defense: ${m_kill_without_ad.toFixed(2)}%\n` +
      `3. Overall tank survivability without air defense: ${tank_survivability_no_ad.toFixed(2)}%\n` +
      `4. Overall tank survivability with air defense: ${tank_survivability_with_ad.toFixed(2)}%\n` +
      `5. The impact of air defense significantly increases the survivability of the tanks.\n`
    );
  }
}

// Example of how to call the method
const airRaidInputs: AirRaidInputs = {
  number_of_aircraft: 10,
  aircraft_accuracy: 75,
  square_kilometers_covered_per_attack: 1,
  total_number_of_armored_vehicles: 50,
  width: 10,
  height: 5,
  k_kill_ratio: 12,
  m_kill_ratio: 38,
  ad_boost_factor: 10,
  lat: 34.0522,
  lon: -118.2437
};

const calculator = new TankSurvivabilityCal(airRaidInputs);
const result = calculator.calculateSurvivabilityWithDensity();
console.log(result);
