// src/survivabilitySlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AirRaidInputs , SurvivabilityState } from '../../../models/SimModels';
// Define the type for inputData


// Thunk for calculating survivability
export const calculateSurvivability = createAsyncThunk(
  'survivability/calculate',
  async (inputData: AirRaidInputs, { rejectWithValue }) => {
    const payload = {
      ...inputData
    };

    try {
      const response = await fetch('http://localhost:8000/calculate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (err) {
      return rejectWithValue((err as Error).message);
    }
  }
);

const initialState: SurvivabilityState = {
  result: null,
  loading: false,
  error: null,
};

const survivabilitySlice = createSlice({
  name: 'survivability',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(calculateSurvivability.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.result = null;
      })
      .addCase(calculateSurvivability.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload;
      })
      .addCase(calculateSurvivability.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string | null;
      });
  },
});

export default survivabilitySlice.reducer;
