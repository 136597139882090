import React from "react";

const LabelIcon = () => {
    return (
        <React.Fragment>
            <div className="card">
                <div className="card-body">
                    <h6 className="mb-4 text-15">Label Buttons</h6>
                    <div className="grid grid-cols-1 gap-5 xl:grid-cols-2">
                        <div className="flex flex-wrap items-center gap-2">
                            <button type="button" className="py-1 text-xs ltr:pl-[calc(theme('spacing.1')_+_26px)] rtl:pr-[calc(theme('spacing.1')_+_26px)] relative px-1.5 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><i className="ri-download-2-line w-[26px] bg-white/10 flex absolute ltr:-left-[1px] rtl:-right-[1px] -bottom-[1px] -top-[1px] items-center justify-center"></i> Download</button>
                            <button type="button" className="px-2 py-1.5 ltr:pl-[calc(theme('spacing.2')_+_30px)] rtl:pr-[calc(theme('spacing.2')_+_30px)] relative text-xs text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><i className="ri-download-2-line w-[30px] bg-white/10 flex absolute ltr:-left-[1px] rtl:-right-[1px] -bottom-[1px] -top-[1px] items-center justify-center"></i> Download</button>
                            <button type="button" className="px-2.5 py-2 ltr:pl-[calc(theme('spacing.2')_*_5.5)] rtl:pr-[calc(theme('spacing.2')_*_5.5)] text-xs relative text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><i className="ri-download-2-line w-[34px] bg-white/10 flex absolute ltr:-left-[1px] rtl:-right-[1px] -bottom-[1px] -top-[1px] items-center justify-center"></i> Download</button>
                            <button type="button" className="relative ltr:pl-[calc(theme('spacing.3')_*_4.2)] rtl:pr-[calc(theme('spacing.3')_*_4.2)] text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><i className="ri-download-2-line w-[37.5px] bg-white/10 flex absolute ltr:-left-[1px] rtl:-right-[1px] -bottom-[1px] -top-[1px] items-center justify-center"></i> Download</button>
                            <button type="button" className="relative px-4 py-2.5 ltr:pl-[calc(theme('spacing.4')_+_44.5px)] rtl:pr-[calc(theme('spacing.4')_+_44.5px)] text-15 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><i className="ri-download-2-line w-[44.5px] bg-white/10 flex absolute ltr:-left-[1px] rtl:-right-[1px] -bottom-[1px] -top-[1px] items-center justify-center"></i> Download</button>
                        </div>
                        <div className="flex flex-wrap items-center gap-2">
                            <button type="button" className="py-1 text-xs rtl:pl-[calc(theme('spacing.1')_+_26px)] ltr:pr-[calc(theme('spacing.1')_+_26px)] relative px-1.5 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><i className="ri-download-2-line w-[26px] bg-white/10 flex absolute rtl:-left-[1px] ltr:-right-[1px] -bottom-[1px] -top-[1px] items-center justify-center"></i> Download</button>
                            <button type="button" className="px-2 py-1.5 rtl:pl-[calc(theme('spacing.2')_+_30px)] ltr:pr-[calc(theme('spacing.2')_+_30px)] relative text-xs text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><i className="ri-download-2-line w-[30px] bg-white/10 flex absolute rtl:-left-[1px] ltr:-right-[1px] -bottom-[1px] -top-[1px] items-center justify-center"></i> Download</button>
                            <button type="button" className="px-2.5 py-2 rtl:pl-[calc(theme('spacing.2')_*_5.5)] ltr:pr-[calc(theme('spacing.2')_*_5.5)] text-xs relative text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><i className="ri-download-2-line w-[34px] bg-white/10 flex absolute rtl:-left-[1px] ltr:-right-[1px] -bottom-[1px] -top-[1px] items-center justify-center"></i> Download</button>
                            <button type="button" className="relative rtl:pl-[calc(theme('spacing.3')_*_4.2)] ltr:pr-[calc(theme('spacing.3')_*_4.2)] text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><i className="ri-download-2-line w-[37.5px] bg-white/10 flex absolute rtl:-left-[1px] ltr:-right-[1px] -bottom-[1px] -top-[1px] items-center justify-center"></i> Download</button>
                            <button type="button" className="relative px-4 py-3 rtl:pl-[calc(theme('spacing.4')_+_44.5px)] ltr:pr-[calc(theme('spacing.4')_+_44.5px)] text-15 text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><i className="ri-download-2-line w-[44.5px] bg-white/10 flex absolute rtl:-left-[1px] ltr:-right-[1px] -bottom-[1px] -top-[1px] items-center justify-center"></i> Download</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <h6 className="mb-4 text-15">Icon with Buttons</h6>
                    <div className="flex flex-wrap items-center gap-2">
                        <button type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"><i className="align-baseline ltr:pr-1 rtl:pl-1 ri-download-2-line"></i> Download</button>
                        <button type="button" className="text-red-500 bg-red-100 btn hover:text-white hover:bg-red-600 focus:text-white focus:bg-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:ring active:ring-red-100 dark:bg-red-500/20 dark:text-red-500 dark:hover:bg-red-500 dark:hover:text-white dark:focus:bg-red-500 dark:focus:text-white dark:active:bg-red-500 dark:active:text-white dark:ring-red-400/20">Close <i className="align-baseline ltr:pl-1 rtl:pr-1 ri-close-line"></i></button>
                        <button type="button" className="bg-white border-dashed text-sky-500 btn border-sky-500 hover:text-sky-500 hover:bg-sky-50 hover:border-sky-600 focus:text-sky-600 focus:bg-sky-50 focus:border-sky-600 active:text-sky-600 active:bg-sky-50 active:border-sky-600 dark:bg-zink-700 dark:ring-sky-400/20 dark:hover:bg-sky-800/20 dark:focus:bg-sky-800/20 dark:active:bg-sky-800/20"><i className="align-baseline ltr:pr-1 rtl:pl-1 ri-refresh-line"></i> Progress</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default LabelIcon;