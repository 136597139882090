import React, { createContext, useState, ReactNode, useContext } from 'react';

// Define the context type
interface ContextType {
  sharedData: Record<string, any>;
  setSharedData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  deploymentStarted: boolean;
  setDeploymentStarted: React.Dispatch<React.SetStateAction<boolean>>;
  travelTime: number;
  setTravelTime: React.Dispatch<React.SetStateAction<number>>;
  lines: L.LatLng[][];
  setLines: React.Dispatch<React.SetStateAction<L.LatLng[][]>>;
}

// Create the context with a default value of undefined
const DataContext = createContext<ContextType | undefined>(undefined);

// Create a custom hook for easier context consumption
const useDataContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useDataContext must be used within a DataContextProvider');
  }
  return context;
};

// Create the provider component
interface DataContextProviderProps {
  children: ReactNode;
}

const DataContextProvider: React.FC<DataContextProviderProps> = ({ children }) => {
  const [sharedData, setSharedData] = useState<Record<string, any>>({});
  const [deploymentStarted, setDeploymentStarted] = useState(false);
  const [travelTime, setTravelTime] = useState(0);
  const [lines, setLines] = useState<L.LatLng[][]>([]);

  return (
    <DataContext.Provider value={{ sharedData, setSharedData, deploymentStarted, setDeploymentStarted, travelTime, setTravelTime, lines, setLines }}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContextProvider, useDataContext };
