// Images
import avatar2 from "assets/images/users/avatar-2.png";
import avatar3 from "assets/images/users/avatar-3.png";
import avatar4 from "assets/images/users/avatar-4.png";
import avatar5 from "assets/images/users/avatar-5.png";
import avatar7 from "assets/images/users/avatar-7.png";
import avatar8 from "assets/images/users/avatar-8.png";
import avatar9 from "assets/images/users/avatar-9.png";
import avatar10 from "assets/images/users/avatar-10.png";

const NotesData = [
    {
        id: 1,
        category: "announcement",
        title: "New Commanding Officer Appointed",
        description: "Lieutenant Colonel Rajesh Kumar has been appointed as the new Commanding Officer of Adir Defence Army Regiment. He brings with him extensive experience and leadership qualities to lead the regiment.",
        img: "new_commanding_officer_image_url.jpg",
        date: "March 10, 2024"
    },
    {
        id: 2,
        category: "events",
        title: "Annual Regimental Day Celebrations",
        description: "The Adir Defence Army Regiment will be celebrating its Annual Regimental Day on March 15, 2024. The event will include a parade, cultural performances, and various sports activities to commemorate the regiment's history and achievements.",
        img: "regimental_day_image_url.jpg",
        date: "March 8, 2024"
    },
    {
        id: 3,
        category: "training",
        title: "Combat Training Exercise",
        description: "A specialized combat training exercise will be conducted for all personnel of Adir Defence Regiment from March 12 to March 16, 2024. The exercise aims to enhance combat readiness and operational effectiveness.",
        img: "combat_training_image_url.jpg",
        date: "March 6, 2024"
    },
    {
        id: 4,
        category: "achievement",
        title: "Adir Regiment Wins Inter-Regimental Shooting Competition",
        description: "The sharpshooting team of Adir Defence Regiment emerged victorious in the Inter-Regimental Shooting Competition held last week. Their exceptional marksmanship skills and teamwork led to this remarkable achievement.",
        img: "shooting_competition_image_url.jpg",
        date: "March 3, 2024"
    },
    {
        id: 5,
        category: "maintenance",
        title: "Scheduled Maintenance of Equipment",
        description: "Routine maintenance of all equipment and vehicles will be carried out from March 11 to March 14, 2024. Personnel are advised to ensure that their equipment is in optimal condition for upcoming operations.",
        img: "maintenance_image_url.jpg",
        date: "March 2, 2024"
    },
    {
        id: 6,
        category: "promotion",
        title: "Promotion of Senior Officers",
        description: "Several senior officers of Adir Defence Regiment have been promoted to higher ranks in recognition of their exemplary service and dedication. Their promotions will take effect from March 15, 2024.",
        img: "promotion_image_url.jpg",
        date: "March 1, 2024"
    },
    {
        id: 7,
        category: "security",
        title: "Security Alert: Increased Vigilance",
        description: "Due to recent intelligence reports indicating potential security threats, all personnel are advised to exercise increased vigilance and adhere strictly to security protocols. Additional security measures have been implemented across all installations.",
        img: "security_alert_image_url.jpg",
        date: "February 28, 2024"
    },
    {
        id: 8,
        category: "community",
        title: "Community Outreach Program",
        description: "Adir Defence Regiment will be organizing a community outreach program on March 20, 2024, aimed at fostering closer ties with the local community. Personnel are encouraged to participate in various community service activities.",
        img: "community_outreach_image_url.jpg",
        date: "February 27, 2024"
    },
    {
        id: 9,
        category: "training",
        title: "Medical First Aid Training",
        description: "A medical first aid training workshop will be conducted for all personnel of Adir Defence Regiment on March 18, 2024. The training will cover basic first aid techniques and procedures to handle medical emergencies effectively.",
        img: "first_aid_training_image_url.jpg",
        date: "February 25, 2024"
    },
    {
        id: 10,
        category: "achievement",
        title: "Adir Regiment Wins Best Drill Performance Award",
        description: "The drill team of Adir Defence Regiment has been awarded the Best Drill Performance Award at the Inter-Regimental Drill Competition. Their precision and synchronization were commendable, earning them this prestigious accolade.",
        img: "drill_performance_image_url.jpg",
        date: "February 22, 2024"
    },
    {
        id: 11,
        category: "announcement",
        title: "Deployment Exercise Briefing",
        description: "A briefing session on the upcoming deployment exercise will be held on March 5, 2024, at 0900 hours. All personnel are required to attend the briefing to receive important instructions and guidelines for the exercise.",
        img: "deployment_briefing_image_url.jpg",
        date: "February 20, 2024"
    },
    {
        id: 12,
        category: "awards",
        title: "Long Service Awards Ceremony",
        description: "The Long Service Awards Ceremony will be held on March 25, 2024, to honor personnel who have completed significant years of service in Adir Defence Regiment. It will be a momentous occasion to celebrate their dedication and commitment.",
        img: "long_service_awards_image_url.jpg",
        date: "February 18, 2024"
    }

];
export { NotesData };