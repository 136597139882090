import React from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import Widgets from './Widgets';
//import WrappedADSitrepRealtime from './WrappedADSitrepRealtime';
import ADSitrepRealtime from './ADSitrepRealtime';






const Analytics = () => {

  return (
    <React.Fragment>
      <BreadCrumb title='Simulator' pageTitle='Operational Status' />
      <div className="grid grid-cols-12 gap-x-5">
        {/* <Widgets /> */}
       
        <ADSitrepRealtime lat={26.2389} lng={73.0243} zoom={6} title="TerraCombat Simulator" />
        {/* <ADSitrepRealtime lat={34.0522} lng={-118.2437} zoom={10} title="Air Defense System SitRep" /> */}

        {/* <LocationBased /> */}
      
       
        
      </div>
    </React.Fragment>
  );
};

export default Analytics;
