// src/features/simulation/simulationSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SimulationState {
  isDeployed: boolean;
  isSimulationRunning: boolean;
  isSimulationStarted: boolean;
  isSimulationPaused: boolean;
  isSimulationStopped: boolean;
  isSimulationCompleted: boolean;
  isSimulationError: boolean;
  isSimulationReset: boolean;
  isSimulationSaved: boolean;
  isAirCorridorDefined: boolean;
}

const initialState: SimulationState = {
  isDeployed: false,
  isSimulationRunning: false,
  isSimulationStarted: false,
  isSimulationPaused: false,
  isSimulationStopped: false,
  isSimulationCompleted: false,
  isSimulationError: false,
  isSimulationReset: false,
  isSimulationSaved: false,
  isAirCorridorDefined: false,
};

const simulationSlice = createSlice({
  name: 'simulation',
  initialState,
  reducers: {
    deploySimulation: (state) => {
      state.isDeployed = true;
    },
    startSimulation: (state) => {
      state.isSimulationRunning = true;
      state.isSimulationStarted = true;
      state.isSimulationPaused = false;
      state.isSimulationStopped = false;
      state.isSimulationCompleted = false;
      state.isSimulationError = false;
      state.isSimulationReset = false;
      state.isSimulationSaved = false;
    },
    pauseSimulation: (state) => {
      state.isSimulationPaused = true;
      state.isSimulationRunning = false;
    },
    stopSimulation: (state) => {
      state.isSimulationStopped = true;
      state.isSimulationRunning = false;
    },
    completeSimulation: (state) => {
      state.isSimulationCompleted = true;
      state.isSimulationRunning = false;
    },
    errorSimulation: (state) => {
      state.isSimulationError = true;
      state.isSimulationRunning = false;
    },
    resetSimulation: (state) => {
      state.isSimulationReset = true;
      state.isSimulationRunning = false;
      state.isSimulationStarted = false;
      state.isSimulationPaused = false;
      state.isSimulationStopped = false;
      state.isSimulationCompleted = false;
      state.isSimulationError = false;
      state.isSimulationSaved = false;
      state.isDeployed = false;
    },
    saveSimulation: (state) => {
      state.isSimulationSaved = true;
    },
    defineAirCorridor: (state) => {
      state.isAirCorridorDefined = true;
    },
  },
});

export const {
  deploySimulation,
  startSimulation,
  pauseSimulation,
  stopSimulation,
  completeSimulation,
  errorSimulation,
  resetSimulation,
  saveSimulation,
  defineAirCorridor,
} = simulationSlice.actions;

export default simulationSlice.reducer;
