import React, { useEffect,useCallback, useState, ChangeEvent,useRef , createContext, useContext } from 'react';
import  { Map as LeafletMap } from 'leaflet';
//import { MapContainer, TileLayer, LayersControl, Marker, Popup } from 'react-leaflet';
import { MapContainer, TileLayer, Marker, Popup, Circle, useMapEvents, useMap, FeatureGroup,LayersControl,Marker as LMarker  } from 'react-leaflet';
import L, { LatLng } from 'leaflet';
import { Switch } from "@material-tailwind/react";
import AirDefensePopup from './AirDefensePopup';
import { Rectangle } from 'react-leaflet';

import { EditControl } from "react-leaflet-draw"
import { renderToString } from 'react-dom/server';

import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import './style/CustomToolbar.css'; 
import 'tippy.js/dist/tippy.css';
import { createPopper } from '@popperjs/core';
import tippy from 'tippy.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';// Make sure the path is correct
import { t } from 'i18next';
import { DataContextProvider, useDataContext } from './utils/data/DataContext'; // Import the context and provider
import { co } from '@fullcalendar/core/internal-common';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "slices";
//import  { AirRaidInputs , SurvivabilityState }   from './utils/data/models/SimModels';
import  { AirRaidInputs,TankSurvivabilityCal,SurvivabilityResult  }   from './utils/algos/TankSurvivabilityUtils';
import { calculateSurvivability } from './utils/data/store/features/survivability/survivabilitySlice';
import axios from 'axios';

import { increment, decrement, incrementByAmount } from './utils/data/store/features/counter/counterSlice';
import {
  deploySimulation,
  startSimulation,
  pauseSimulation,
  stopSimulation,
  completeSimulation,
  errorSimulation,
  resetSimulation,
  saveSimulation,
  defineAirCorridor,
} from './utils/data/store/features/simulation/SimulationSlice';
import ReactDOM from 'react-dom';

// Create a context to share data between components
// Define a type for the context value
// type ContextType = {
//   sharedData: Record<string, any>; // sharedData is an empty JSON object {}
//   setSharedData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
//   deploymentStarted: boolean;
//   setDeploymentStarted: React.Dispatch<React.SetStateAction<boolean>>;
//   travelTime: number;
//   setTravelTime: React.Dispatch<React.SetStateAction<number>>;
//   lines: L.LatLng[][]; // Store lines
//   setLines: React.Dispatch<React.SetStateAction<L.LatLng[][]>>;
// }



 let deploymentMode = false;
 let convergenceMode = false;
 let speedBoost=30;
// declare vaiable to store line 
let airCorridorLine: L.LatLng[] = [];

let selectedAircraftData: AttackPackage[] = [];

//const line: L.LatLng[] = [];


// // Create a context with the specified type for the value
// const DataContext = React.createContext<ContextType | undefined>(undefined);

// const useDataContext = () => {
//   const context = useContext(DataContext);
//   if (!context) {
//     throw new Error('useDataContext must be used within a DataContextProvider');
//   }
//   return context;
// };

interface CustomControl extends L.Control {
  createButton(text: string, container: HTMLElement, onClick: () => void): HTMLButtonElement;
}







interface CustomControlOptions extends L.ControlOptions {
  position: 'topright' | 'topleft' | 'bottomright' | 'bottomleft'; // Restrict position to the allowed values
}


// Define a custom toolbar control

const CustomToolbarControl = L.Control.extend({
  map: L.Map,

  options: {
    position: 'topright'
  } as CustomControlOptions,


  
  startButton: null as HTMLButtonElement | null, // Define startButton as a property
  deployButton: null as HTMLButtonElement | null, // Define deployButton as a property

  onAdd: function (map: L.Map): HTMLElement {
    var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control custom-toolbar');
    container.style.backgroundColor = '#ffffff';
    container.style.border = '2px solid grey';
    container.style.borderRadius = '4px';
    container.style.padding = '5px';
    container.style.boxShadow = '0 2px 6px rgba(0,0,0,0.3)';
    container.style.display = 'flex';
    container.style.flexDirection = 'row'; // Make the toolbar horizontal

    this.startButton = this.createToggleButton('<i class="fas fa-play"></i>', 'Start Simulation', 'Stop Simulation', container, () => {
      if (this.startButton && this.startButton.innerHTML.includes('fa-play')) {
        this.startButton.innerHTML = '<i class="fas fa-stop"></i>';
        console.log('Start clicked  :   '+ airCorridorLine);
        // add lat and lon to air corridor start 
        
        
        console.log('Attack Launched  :   '+ selectedAircraftData);
        this.showNotification('Simulation started with ' + selectedAircraftData.length + ' aircrafts');


        // Call moveAircraftIcon for each selected aircraft
       // Call moveAircraftIcon for each selected aircraft with index
    selectedAircraftData.forEach((aircraft, index) => {
      if (airCorridorLine.length > 1) {
        moveAircraftIcon(aircraft, airCorridorLine, map, index);
      }
    });

        
      } else if (this.startButton) {
        this.startButton.innerHTML = '<i class="fas fa-play"></i>';
        this.showNotification('Simulation stopped');
        airCorridorLine = [];
      }
    });

    this.createDivider(container);

    this.createButton('<i class="fas fa-save"></i>', 'Save', container, () => {
      console.log('Save clicked');
    });

    this.createDivider(container);

    this.deployButton = this.createToggleButton('<i class="fas fa-upload"></i>', 'Deploy', 'Finalize Deployment', container, () => {
      if (this.deployButton && this.deployButton.innerHTML.includes('fa-upload')) {
        this.deployButton.innerHTML = '<i class="fas fa-check"></i>';
        this.showNotification('Please select area on Map to start deployment');
        deploymentMode = true;
      } else if (this.deployButton) {
        this.deployButton.innerHTML = '<i class="fas fa-upload"></i>';
        this.showNotification('Deployment finished');
        deploymentMode = false;
      }
    });

    this.createDivider(container);

    this.createButton('<i class="fas fa-plus"></i>', 'Add New Base', container, () => {
      this.showNotification('Add New Base clicked');
    });

    this.createDivider(container);

    this.createButton('<i class="fas fa-tasks"></i>', 'Manage Weapon Package', container, () => {
      this.showNotification('Manage Weapon Package clicked');
    });

    this.createDivider(container);

    this.createButton('<i class="fas fa-cube"></i>', 'New Asset', container, () => {
      this.showNotification('New Asset clicked');
    });

    return container;
  },

  createButton: function (iconHtml: string, tooltipText: string, container: HTMLElement, onClick: () => void): HTMLButtonElement {
    var button = L.DomUtil.create('button', 'leaflet-buttons', container);
    button.innerHTML = iconHtml;
    button.style.margin = '2px';
    button.style.width = '28px'; // Reduce the width by 30%
    button.style.height = '28px'; // Reduce the height by 30%
    button.style.display = 'flex';
    button.style.alignItems = 'center';
    button.style.justifyContent = 'center';
    button.style.padding = '6px'; // Reduce padding accordingly
    button.style.border = 'none';
    button.style.borderRadius = '4px';
    button.style.backgroundColor = '#f0f0f0';
    button.style.color = '#333';
    button.style.cursor = 'pointer';
    button.style.transition = 'background-color 0.3s, color 0.3s';

    L.DomEvent.on(button, 'mouseover', () => {
      button.style.backgroundColor = '#007bff';
      button.style.color = '#fff';
    });

    L.DomEvent.on(button, 'mouseout', () => {
      button.style.backgroundColor = '#f0f0f0';
      button.style.color = '#333';
    });

    L.DomEvent.on(button, 'click', function (e) {
      L.DomEvent.stopPropagation(e);
      L.DomEvent.preventDefault(e);
      onClick();
    });

    // Initialize Tippy.js tooltip
    tippy(button, {
      content: tooltipText,
      placement: 'bottom', // Change the tooltip placement to bottom
    });

    return button;
  },

  createToggleButton: function (iconHtml: string, tooltipTextStart: string, tooltipTextStop: string, container: HTMLElement, onClick: () => void): HTMLButtonElement {
    var button = this.createButton(iconHtml, tooltipTextStart, container, onClick);

    // Initialize Tippy.js tooltip with dynamic content
    tippy(button, {
      content: tooltipTextStart,
      placement: 'bottom', // Change the tooltip placement to bottom
      onShow(instance) {
        instance.setContent(button.innerHTML.includes('fa-play') || button.innerHTML.includes('fa-upload') ? tooltipTextStart : tooltipTextStop);
      }
    });

    return button;
  },

  createDivider: function (container: HTMLElement) {
    var divider = L.DomUtil.create('div', 'leaflet-divider', container);
    divider.style.width = '1px'; // Make the divider vertical
    divider.style.height = '100%';
    divider.style.backgroundColor = '#ccc';
    divider.style.margin = '0 5px'; // Adjust margin for horizontal layout
    return divider;
  },

  showNotification: function (message: string) {
    const notification = document.createElement('div');
    notification.style.position = 'fixed';
    notification.style.top = '10px';
    notification.style.right = '10px';
    notification.style.padding = '10px 20px';
    notification.style.backgroundColor = '#333';
    notification.style.color = '#fff';
    notification.style.borderRadius = '4px';
    notification.style.boxShadow = '0 2px 6px rgba(0,0,0,0.3)';
    notification.style.zIndex = '1000';
    notification.innerText = message;
    document.body.appendChild(notification);

    setTimeout(() => {
      document.body.removeChild(notification);
    }, 2000); // Show notification for 2 seconds
  }
});


const getAircraftIcon = (aircraftId: string) => {
  switch (aircraftId) {
    case AircraftId.F7:
      return f7pIcon;
    case AircraftId.F7PG:
      return f7pgIcon;
    case AircraftId.F16:
      return f16cdIcon;
    case AircraftId.JF17:
      return j17Icon;
    case AircraftId.MIRAGE:
      return mir34Icon;


    // Add cases for other aircraft types if needed
    default:
      return f16cdIcon;
  }
};

function moveAircraftIcon(aircraft: AttackPackage, waypoints: L.LatLng[], map: L.Map, index: number) {
  const icon = getAircraftIcon(aircraft.aircraftId);
  const offsetDistance = 0.70;
  const offsets = [
    [0, 0],
    [offsetDistance, offsetDistance],
    [-offsetDistance, offsetDistance],
    [offsetDistance, -offsetDistance],
    [-offsetDistance, -offsetDistance],
  ];

  const offset = offsets[index % offsets.length];
  const adjustedStartPos = new L.LatLng(waypoints[0].lat + offset[0], waypoints[0].lng + offset[1]);
  const marker = L.marker(adjustedStartPos, { icon }).addTo(map);

  interface SurvivabilityResponse {
    overall_survivability: number;
    k_kill: number;
    m_kill: number;
    survivability_with_ad: number;
    k_kill_with_ad: number;
    m_kill_with_ad: number;
    calculation_explanation: string;
    key_insights: string;
  }

  const speedFactor = 30;

  function animateSegment(startPos: L.LatLng, endPos: L.LatLng, duration: number) {
    const distance = startPos.distanceTo(endPos);
    const latLngInterpolator = (t: number) => {
      return new L.LatLng(
        startPos.lat + t * (endPos.lat - startPos.lat),
        startPos.lng + t * (endPos.lng - startPos.lng)
      );
    };

    function showTab(tabId: string | null) {
      const tabs = document.querySelectorAll('.tab-content');
      tabs.forEach(tab => {
        tab.classList.add('hidden');
      });
      if (tabId) {
        document.getElementById(tabId)?.classList.remove('hidden');
      }

      const tabButtons = document.querySelectorAll('.tab-button');
      tabButtons.forEach(button => {
        button.classList.remove('active-tab', 'border-l', 'border-t', 'border-r', 'rounded-t', 'text-blue-700');
        button.classList.add('text-blue-500', 'hover:text-blue-800');
      });
      document.querySelector(`.tab-button[data-tab="${tabId}"]`)?.classList.add('active-tab', 'border-l', 'border-t', 'border-r', 'rounded-t', 'text-blue-700');
    }

    let startTime: number | null = null;

    function animate(timestamp: number) {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / (duration / speedFactor), 1);
      marker.setLatLng(latLngInterpolator(progress));
      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        const nextIndex = waypoints.indexOf(endPos) + 1;
        if (nextIndex < waypoints.length) {
          const nextPos = waypoints[nextIndex];
          const nextDistance = endPos.distanceTo(nextPos);
          const nextDuration = (nextDistance / 1000) / aircraft.speed * 3600 * 1000;
          animateSegment(endPos, nextPos, nextDuration);
        } else {
          console.log('Attack Completed');

          const deploymentBounds = L.latLngBounds(deploymentBoundsData);
          //L.latLngBounds(waypoints);

          map.fitBounds(deploymentBounds, { maxZoom: 18 });

          // Create an instance of the calculator with the deployedData
       // Replace 'path/to/TankSurvivabilityCal' with the actual path to the module or class.

        const calculator = new TankSurvivabilityCal(deployedData);

        // Call the calculateSurvivabilityWithDensity method
        const result = calculator.calculateSurvivabilityWithDensity();

        // Convert result to JSON string
const json = JSON.stringify(result, null, 2);
console.log('Survivability API response:', result);

let resp: SurvivabilityResult = result;

console.log('Survivability API response:', resp.calculationExplanation);

        // Convert result to JSON string
    // Create popup content with legends
// Create popup content with legends
const popupContent = `
  <div class="p-4 bg-white rounded-lg shadow-lg max-w-md mx-auto">
    <h3 class="text-xl font-bold mb-2">Survivability Report</h3>
    <p class="mb-2"><strong>Overall Survivability:</strong> ${resp.overallSurvivability.toFixed(2)}%</p>
    <p class="mb-2"><strong>K-Kill:</strong> ${resp.kKill.toFixed(2)}%</p>
    <p class="mb-2"><strong>M-Kill:</strong> ${resp.mKill.toFixed(2)}%</p>
    <p class="mb-2"><strong>Survivability with AD:</strong> ${resp.survivabilityWithAD.toFixed(2)}%</p>
    <p class="mb-2"><strong>K-Kill with AD:</strong> ${resp.kKillWithAD.toFixed(2)}%</p>
    <p class="mb-2"><strong>M-Kill with AD:</strong> ${resp.mKillWithAD.toFixed(2)}%</p>
    <div class="mt-4">
      <ul class="flex border-b">
        <li class="-mb-px mr-1">
          <button class="bg-white inline-block py-2 px-4 text-blue-700 font-semibold tab-button active-tab" data-tab="key-insights">Key Insights</button>
        </li>
        <li class="mr-1">
          <button class="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-500 hover:text-blue-800 font-semibold tab-button" data-tab="calc-explanation">Calculation Explanation</button>
        </li>
      </ul>
      <div id="key-insights" class="tab-content mt-4">
        <pre class="whitespace-pre-wrap max-h-60 overflow-y-auto">${resp.keyInsights}</pre>
      </div>
      <div id="calc-explanation" class="tab-content mt-4 hidden">
        <pre class="whitespace-pre-wrap mb-4 max-h-60 overflow-y-auto">${resp.calculationExplanation}</pre>
      </div>
    </div>
    <div class="legend mt-4">
      <h4 class="text-lg font-bold mb-2">Legend</h4>
      <div class="flex space-x-4">
        <div class="flex items-center">
          <span style="color: red;">●</span>
          <span class="ml-1">K-Kill</span>
        </div>
        <div class="flex items-center">
          <span style="color: orange;">●</span>
          <span class="ml-1">M-Kill</span>
        </div>
        <div class="flex items-center">
          <span style="color: blue;">●</span>
          <span class="ml-1">OK</span>
        </div>
      </div>
    </div>
  </div>
`;

      const popup = L.popup()
      .setLatLng(endPos)
      .setContent(popupContent)
      .openOn(map);

    document.querySelectorAll('.tab-button').forEach(button => {
      button.addEventListener('click', (event) => {
        const tabId = (event.target as HTMLElement).getAttribute('data-tab');
        showTab(tabId);
      });
    });

    const totalVehicles = deployedData.total_number_of_armored_vehicles;
    for (let i = 0; i < totalVehicles; i++) {
      const lat = deploymentBounds.getSouthWest().lat + Math.random() * (deploymentBounds.getNorthEast().lat - deploymentBounds.getSouthWest().lat);
      const lng = deploymentBounds.getSouthWest().lng + Math.random() * (deploymentBounds.getNorthEast().lng - deploymentBounds.getSouthWest().lng);
      L.circleMarker([lat, lng], { radius: 3, color: 'blue' }).addTo(map);
    }

    const mKills = Math.round((resp.mKill / 100) * totalVehicles);
    for (let i = 0; i < mKills; i++) {
      const lat = deploymentBounds.getSouthWest().lat + Math.random() * (deploymentBounds.getNorthEast().lat - deploymentBounds.getSouthWest().lat);
      const lng = deploymentBounds.getSouthWest().lng + Math.random() * (deploymentBounds.getNorthEast().lng - deploymentBounds.getSouthWest().lng);
      L.circleMarker([lat, lng], { radius: 3, color: 'orange' }).addTo(map);
    }

    const kKills = Math.round((resp.kKill / 100) * totalVehicles);
    for (let i = 0; i < kKills; i++) {
      const lat = deploymentBounds.getSouthWest().lat + Math.random() * (deploymentBounds.getNorthEast().lat - deploymentBounds.getSouthWest().lat);
      const lng = deploymentBounds.getSouthWest().lng + Math.random() * (deploymentBounds.getNorthEast().lng - deploymentBounds.getSouthWest().lng);
      L.circleMarker([lat, lng], { radius: 3, color: 'red' }).addTo(map);
    }



        //   axios.post('http://localhost:8000/api/v1/survivability/calculate', deployedData)
        //     .then(response => {
        //       const json = JSON.stringify(response, null, 2);
        //       response.data = json;
        //       console.log('Survivability API response:', response);

        //       let resp: SurvivabilityResponse = JSON.parse(json);
        //       console.log('Survivability API response:', resp.calculation_explanation);

        //       const popupContent = `
        //         <div class="p-4 bg-white rounded-lg shadow-lg max-w-md mx-auto">
        //           <h3 class="text-xl font-bold mb-2">Survivability Report</h3>
        //           <p class="mb-2"><strong>Overall Survivability:</strong> ${resp.overall_survivability.toFixed(2)}%</p>
        //           <p class="mb-2"><strong>K-Kill:</strong> ${resp.k_kill.toFixed(2)}%</p>
        //           <p class="mb-2"><strong>M-Kill:</strong> ${resp.m_kill.toFixed(2)}%</p>
        //           <p class="mb-2"><strong>Survivability with AD:</strong> ${resp.survivability_with_ad.toFixed(2)}%</p>
        //           <p class="mb-2"><strong>K-Kill with AD:</strong> ${resp.k_kill_with_ad.toFixed(2)}%</p>
        //           <p class="mb-2"><strong>M-Kill with AD:</strong> ${resp.m_kill_with_ad.toFixed(2)}%</p>
        //           <div class="mt-4">
        //             <ul class="flex border-b">
        //               <li class="-mb-px mr-1">
        //                 <button class="bg-white inline-block py-2 px-4 text-blue-700 font-semibold tab-button active-tab" data-tab="key-insights">Key Insights</button>
        //               </li>
        //               <li class="mr-1">
        //                 <button class="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-500 hover:text-blue-800 font-semibold tab-button" data-tab="calc-explanation">Calculation Explanation</button>
        //               </li>
        //             </ul>
        //             <div id="key-insights" class="tab-content mt-4">
        //               <pre class="whitespace-pre-wrap max-h-60 overflow-y-auto">${resp.key_insights}</pre>
        //             </div>
        //             <div id="calc-explanation" class="tab-content mt-4 hidden">
        //               <pre class="whitespace-pre-wrap mb-4 max-h-60 overflow-y-auto">${resp.calculation_explanation}</pre>
        //             </div>
        //           </div>
        //         </div>
        //       `;

        //       const popup = L.popup()
        //         .setLatLng(endPos)
        //         .setContent(popupContent)
        //         .openOn(map);

        //       document.querySelectorAll('.tab-button').forEach(button => {
        //         button.addEventListener('click', (event) => {
        //           const tabId = (event.target as HTMLElement).getAttribute('data-tab');
        //           showTab(tabId);
        //         });
        //       });

        //       const totalVehicles = deployedData.total_number_of_armored_vehicles;
        //       for (let i = 0; i < totalVehicles; i++) {
        //         const lat = deploymentBounds.getSouthWest().lat + Math.random() * (deploymentBounds.getNorthEast().lat - deploymentBounds.getSouthWest().lat);
        //         const lng = deploymentBounds.getSouthWest().lng + Math.random() * (deploymentBounds.getNorthEast().lng - deploymentBounds.getSouthWest().lng);
        //         L.circleMarker([lat, lng], { radius: 3, color: 'blue' }).addTo(map);
        //       }

        //       const mKills = Math.round((resp.m_kill / 100) * totalVehicles);
        //       for (let i = 0; i < mKills; i++) {
        //         const lat = deploymentBounds.getSouthWest().lat + Math.random() * (deploymentBounds.getNorthEast().lat - deploymentBounds.getSouthWest().lat);
        //         const lng = deploymentBounds.getSouthWest().lng + Math.random() * (deploymentBounds.getNorthEast().lng - deploymentBounds.getSouthWest().lng);
        //         L.circleMarker([lat, lng], { radius: 3, color: 'orange' }).addTo(map);
        //       }

        //       const kKills = Math.round((resp.k_kill / 100) * totalVehicles);
        //       for (let i = 0; i < kKills; i++) {
        //         const lat = deploymentBounds.getSouthWest().lat + Math.random() * (deploymentBounds.getNorthEast().lat - deploymentBounds.getSouthWest().lat);
        //         const lng = deploymentBounds.getSouthWest().lng + Math.random() * (deploymentBounds.getNorthEast().lng - deploymentBounds.getSouthWest().lng);
        //         L.circleMarker([lat, lng], { radius: 3, color: 'red' }).addTo(map);
        //       }

        //     })
        //     .catch(error => {
        //       console.error('Error calling the survivability API:', error);
        //       L.popup()
        //         .setLatLng(endPos)
        //         .setContent('Error fetching survivability data: ' + error.message)
        //         .openOn(map);
        //     });
         }
      }
    }

    requestAnimationFrame(animate);
  }

  if (waypoints.length > 1) {
    const startPos = waypoints[0];
    const endPos = waypoints[1];
    const distance = startPos.distanceTo(endPos);
    const duration = (distance / 1000) / aircraft.speed * 3600 * 1000 / speedFactor; 
    animateSegment(startPos, endPos, duration);
  }
}



// function moveAircraftIcon(aircraft: AttackPackage, waypoints: L.LatLng[], map: L.Map, index: number) {
 



  
//   // Get the map instance
//   const icon = getAircraftIcon(aircraft.aircraftId);
//  // const dispatch = useDispatch();

//   // Calculate offsets for triangular formation
//   const offsetDistance = 0.50;
//   const speedFactor = 30; // Increase this factor to speed up the animation// Adjust this value to control the distance between aircraft
//   const offsets = [
//     [0, 0], // First aircraft at the center
//     [offsetDistance, offsetDistance], // Second aircraft to the right and up
//     [-offsetDistance, offsetDistance], // Third aircraft to the left and up
//     [offsetDistance, -offsetDistance], // Fourth aircraft to the right and down
//     [-offsetDistance, -offsetDistance], // Fifth aircraft to the left and down
//   ];

//   const offset = offsets[index % offsets.length];
//   const adjustedStartPos = new L.LatLng(waypoints[0].lat + offset[0], waypoints[0].lng + offset[1]);
//   const marker = L.marker(adjustedStartPos, { icon }).addTo(map);

//   interface SurvivabilityResponse {
//     overall_survivability: number;
//     k_kill: number;
//     m_kill: number;
//     survivability_with_ad: number;
//     k_kill_with_ad: number;
//     m_kill_with_ad: number;
//     calculation_explanation: string;
//     key_insights: string;
//   }

//   function animateSegment(startPos: L.LatLng, endPos: L.LatLng, duration: number) {
//     const distance = startPos.distanceTo(endPos); // distance in meters
//     const latLngInterpolator = (t: number) => {
//       return new L.LatLng(
//         startPos.lat + t * (endPos.lat - startPos.lat),
//         startPos.lng + t * (endPos.lng - startPos.lng)
//       );
//     };

//     // const req = {
//     //   number_of_aircraft: 10,
//     //   aircraft_accuracy: 80,
//     //   square_kilometers_covered_per_attack: 5,
//     //   total_number_of_armored_vehicles: 50,
//     //   width: 10,
//     //   height: 10,
//     //   k_kill_ratio: 30,
//     //   m_kill_ratio: 20,
//     //   ad_boost_factor: 15,
//     //   lat: 0,
//     //   lon: 0
//     // };
//     //

//     //log deployedData
//     console.log('Deployed Data:', deployedData);


    
//      // Function to show the selected tab content
// // Function to show the selected tab content
// function showTab(tabId: string | null) {
//   const tabs = document.querySelectorAll('.tab-content');
//   tabs.forEach(tab => {
//     tab.classList.add('hidden');
//   });
//   if (tabId) {
//     document.getElementById(tabId)?.classList.remove('hidden');
//   }

//   // Highlight the active tab button
//   const tabButtons = document.querySelectorAll('.tab-button');
//   tabButtons.forEach(button => {
//     button.classList.remove('active-tab', 'border-l', 'border-t', 'border-r', 'rounded-t', 'text-blue-700');
//     button.classList.add('text-blue-500', 'hover:text-blue-800');
//   });
//   document.querySelector(`.tab-button[data-tab="${tabId}"]`)?.classList.add('active-tab', 'border-l', 'border-t', 'border-r', 'rounded-t', 'text-blue-700');
// }

// // Add initial highlight to the first tab
// document.addEventListener('DOMContentLoaded', () => {
//   document.querySelector('.tab-button[data-tab="calc-explanation"]')?.classList.add('active-tab', 'border-l', 'border-t', 'border-r', 'rounded-t', 'text-blue-700');
// });

//     let startTime: number | null = null;

//     function animate(timestamp: number) {
//       if (!startTime) startTime = timestamp;
//       //const progress = Math.min((timestamp - startTime) / duration, 1);
//       const progress = Math.min((timestamp - startTime) / (duration / speedFactor), 1);
//       marker.setLatLng(latLngInterpolator(progress));
//       if (progress < 1) {
//         requestAnimationFrame(animate);
//       } else {
//         const nextIndex = waypoints.indexOf(endPos) + 1;
//         if (nextIndex < waypoints.length) {
//           const nextPos = waypoints[nextIndex];
//           const nextDistance = endPos.distanceTo(nextPos);
//         // const nextDuration = (nextDistance / 1000) / aircraft.speed * 3600 * 1000 * 0.1; // Reduce the duration by multiplying by 0.1
//         const nextDuration = (nextDistance / 1000) / aircraft.speed * 3600 * 1000;
//           animateSegment(endPos, nextPos, nextDuration);
//         }else{
//           //
//           console.log('Attack Completed');

//            // Convert waypoints to LatLngTuple for LatLngBounds
//            const latLngTuples: L.LatLngTuple[] = waypoints.map(waypoint => [waypoint.lat, waypoint.lng]);

//            // Zoom into the deployment area
//            const deploymentBounds = new L.LatLngBounds(latLngTuples);
//            map.fitBounds(deploymentBounds, { maxZoom: 15 });

//            // Zoom into the deployment area
//           //

//           //log the request
//           console.log('Attack Package:', deployedData);

//        // API call after animation is completed
//       // API call after animation is completed

//       axios.post('http://localhost:8000/api/v1/survivability/calculate', 
//       // req
//       deployedData
//     ).then(response => {
//       const json =JSON.stringify(response, null, 2)
//       response.data = json;
//       //log the response
//       console.log('Survivability API response:', response);

//       let resp: SurvivabilityResponse = JSON.parse(json);
//       console.log('Survivability API response:', resp.calculation_explanation);


      
      
//       // Process the API response
//       //const { overall_survivability, k_kill, m_kill, survivability_with_ad, k_kill_with_ad, m_kill_with_ad, calculation_explanation, key_insights } = response.data ;

//       // Format the content for the popup
//        // Format the content for the popup with Tailwind CSS classes and tabs
// // Format the content for the popup with Tailwind CSS classes and tabs
//   // Format the content for the popup with Tailwind CSS classes and tabs
//   const popupContent = `
//   <div class="p-4 bg-white rounded-lg shadow-lg max-w-md mx-auto">
//     <h3 class="text-xl font-bold mb-2">Survivability Report</h3>
//     <p class="mb-2"><strong>Overall Survivability:</strong> ${resp.overall_survivability.toFixed(2)}%</p>
//     <p class="mb-2"><strong>K-Kill:</strong> ${resp.k_kill.toFixed(2)}%</p>
//     <p class="mb-2"><strong>M-Kill:</strong> ${resp.m_kill.toFixed(2)}%</p>
//     <p class="mb-2"><strong>Survivability with AD:</strong> ${resp.survivability_with_ad.toFixed(2)}%</p>
//     <p class="mb-2"><strong>K-Kill with AD:</strong> ${resp.k_kill_with_ad.toFixed(2)}%</p>
//     <p class="mb-2"><strong>M-Kill with AD:</strong> ${resp.m_kill_with_ad.toFixed(2)}%</p>
//     <div class="mt-4">
//       <ul class="flex border-b">
//         <li class="-mb-px mr-1">
//           <button class="bg-white inline-block py-2 px-4 text-blue-700 font-semibold tab-button active-tab" data-tab="key-insights">Key Insights</button>
//         </li>
//         <li class="mr-1">
//           <button class="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-blue-500 hover:text-blue-800 font-semibold tab-button" data-tab="calc-explanation">Calculation Explanation</button>
//         </li>
//       </ul>
//       <div id="key-insights" class="tab-content mt-4">
//         <pre class="whitespace-pre-wrap max-h-60 overflow-y-auto">${resp.key_insights}</pre>
//       </div>
//       <div id="calc-explanation" class="tab-content mt-4 hidden">
//         <pre class="whitespace-pre-wrap mb-4 max-h-60 overflow-y-auto">${resp.calculation_explanation}</pre>
//       </div>
//     </div>
//   </div>
// `;

//     //   // Show results in a popup
//     //   L.popup()
//     //     .setLatLng(endPos)
//     //     .setContent(popupContent)
//     //     .openOn(map);
//     // }).catch(error => {
//     //   console.error('Error calling the survivability API:', error);
//     //   L.popup()
//     //     .setLatLng(endPos)
//     //     .setContent('Error fetching survivability data.' + error.message)
//     //     .openOn(map);
//     // });

//        // Show results in a popup using Leaflet
//        const popup = L.popup()
//        .setLatLng(endPos)
//        .setContent(popupContent)
//        .openOn(map);
 
//      // Add event listeners to tab buttons
//      document.querySelectorAll('.tab-button').forEach(button => {
//        button.addEventListener('click', (event) => {
//          const tabId = (event.target as HTMLElement).getAttribute('data-tab');
//          showTab(tabId);
//        });
//      });
 
//    })
//    .catch(error => {
//      console.error('Error calling the survivability API:', error);
//      L.popup()
//        .setLatLng(endPos)
//        .setContent('Error fetching survivability data: ' + error.message)
//        .openOn(map);
//    });
   

//          // dispatch(calculateSurvivability(inputData)); // Dispatch the action with the inputData
//         }
//       }
//     }

//     requestAnimationFrame(animate);
//   }

//   if (waypoints.length > 1) {
//     const startPos = waypoints[0];
//     const endPos = waypoints[1];
//     const distance = startPos.distanceTo(endPos);
//     const duration = (distance / 1000) / aircraft.speed * 3600 * 1000;
//     animateSegment(startPos, endPos, duration);
//   }
// }




















export let deploymentBoundsData: [number, number][];


//Create global variable to store deployment data
export let deployedData: AirRaidInputs = {
  number_of_aircraft: 0,
  aircraft_accuracy: 20,
  square_kilometers_covered_per_attack: 1,
  total_number_of_armored_vehicles: 0,

  width: 0,
  height: 0,

  k_kill_ratio: 10,
  m_kill_ratio: 30,
  ad_boost_factor: 10,
  lat: 0,
  lon: 0,
  
};


// Define an enum for aircraft IDs
enum AircraftId {
  JF17 = "JF-17",
  F16 = "F-16",
  MIRAGE = "Mirage 3/5",
  F7 = "F-7",
  F7PG = "F-7PG",

}

// Define an enum for base names
enum BaseName {
  RISALEWALA = "Risalewala Air Force Base",
  RAJANPUR = "Rajanpur",
  RAHIM_YAR_KHAN = "Rahim Yar Khan",
  LAHORE = "Lahore",
  VIHARI = "Vihari",
  MULTAN = "Multan",
  SARGODHA = "Sargodha",
  RAFIQUI = "Rafiqui",
}
enum BaseType  {
  UAV = "UAC",
  HELI = "Attack Helicopter",
  FIGHTER = "Fighter Jets",
  FIGHTER_HELI = "Fighter Jets &Attack Helicopter",
  BOMBER = 'bomber'
 }

 enum Region {
  NAC = "Northern Air Command, PESHAWAR", // Northern Air Command
  CAC = "Central Air Command,LAHORE",   // Central Air Command
  SAC = " Southern Air Command, SHER-E-FAISAL", // Southern Air Command
  ADC = "Air Defence Command,CHAKLALA", // Air Defence Command
  AFSC = "Air Force Strategic Command,ISLAMABAD" // Air Force Strategic Command
}
interface Deployment {
  aircraftId: string; // ID of the aircraft
  quantityDeployed: number; // Quantity of aircraft deployed
}

interface DeploymentData {
  [airbaseId: string]: Deployment[]; // Dictionary where keys are airbase IDs and values are arrays of deployments
}

interface Airbase {
  id: string;
  name: string;
  latitude: number;
  longitude: number;
  icon: L.Icon; 
  type : string;
  region : string// reference to Leaflet icon object
}

interface Aircraft {
  id: string;
  name: string;
  icon: L.Icon;
  description: string;
  range: number; // in kilometers
  maxSpeed: number; // in kilometers per hour
}

function DeploymentComponent() {
  const [popupPosition, setPopupPosition] = useState<[number, number] | null>(null);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [rectangleBounds, setRectangleBounds] = useState<{ bounds: [number, number][]; color: string } | null>(null);
  const [deployments, setDeployments] = useState<Deployment[]>([]);
  // Access shared data and setter function using useContext hook
 

  const deploymentScenarios = [
    {
      name: "CSA CG",
      impact: 30,
      mkill: 33,
      pkill: 11,
      length: 2,
      width: 2,
      description: "Combat Staging Area",
      av: 66,
      count: 1,
      color: 'blue'
    },
    {
      name: "Bridge Head",
      impact: 40,
      mkill: 36,
      pkill: 11,
      length: 3,
      width: 2,
      description: "Temporary Bridge",
      av: 14,
      count: 1,
      color: 'red'
    },
    {
      name: "Pivot",
      impact: 30,
      mkill: 30,
      pkill: 10,
      length: 5,
      width: 3,
      description: "Pivot",
      av: 65,
      count: 1,
      color: 'green'
    },
    {
      name: "Lean On",
      impact: 30,
      mkill: 70,
      pkill: 23,
      length: 25,
      width: 3,
      description: "Lean On",
      av: 70,
      count: 1,
      color: 'orange'
    }
  ];

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleDeploy = () => {
    if (popupPosition) {
      const selectedScenario = deploymentScenarios.find(scenario => scenario.name === selectedOption);
      if (selectedScenario) {
        const lat = popupPosition[0];
        const lng = popupPosition[1];
        const halfWidth = selectedScenario.length / 2 / 111.32; // 1 degree of latitude is approximately 111.32 kilometers
        const halfHeight = selectedScenario.width / 2 / (111.32 * Math.cos((lat * Math.PI) / 180)); // 1 degree of longitude varies with latitude
        const bounds: [number, number][] = [
          [lat + halfHeight, lng - halfWidth], // Top-left corner
          [lat - halfHeight, lng + halfWidth], // Bottom-right corner
        ];
        // Draw the rectangle on the map
        setRectangleBounds({ bounds, color: selectedScenario.color });

        // Store the deployment data
        setDeployments(prevDeployments => [
          ...prevDeployments,
          {
            aircraftId: selectedScenario.name,
            quantityDeployed: selectedScenario.count,
            lat,
            lng,
            width: selectedScenario.width,
            height: selectedScenario.length,
          },
        ]);

        //setSharedData(deployments);

        deployedData.width = selectedScenario.width;
        deployedData.height = selectedScenario.length;
        deployedData.total_number_of_armored_vehicles = selectedScenario.av * selectedScenario.count;
        deployedData.lat = lat;
        deployedData.lon = lng;

        deployedData.m_kill_ratio = selectedScenario.mkill;
        deployedData.k_kill_ratio = selectedScenario.pkill;

        deploymentBoundsData=bounds;






        //call the function to calculate survivability

        // print the deployment data on console
        console.log('Deployments:', deployments);
        //close the popup
        setPopupPosition(null);

      }
    }
  };





  const map = useMapEvents({
    click: (e) => {
      const { lat, lng } = e.latlng;
      if (e.type === 'click') {
        if(deploymentMode){
          // Set the popup position
          setPopupPosition([lat, lng]);

        }
        
      }
    },
  });

  const mapEr = useMap();





  useEffect(() => {
    const toolbar = new CustomToolbarControl(); // Pass mapEr as the second argument
    mapEr.addControl(toolbar);
    if (rectangleBounds) {
      mapEr.fitBounds(rectangleBounds.bounds);
    }
    return () => {
      map.removeControl(toolbar);
    };
  }, [mapEr, rectangleBounds]);
  

  return (
    <>
      {popupPosition && (
        <Popup position={popupPosition}>
          <div className="p-4">
            <h2 className="text-lg font-bold mb-4">Deploy Here</h2>
            <div className="mb-4">
              <p className="text-sm">Latitude: {popupPosition[0].toFixed(4)}</p>
              <p className="text-sm">Longitude: {popupPosition[1].toFixed(4)}</p>
            </div>
            <div className="mb-4">
              <select
                className="px-4 py-2 border rounded"
                value={selectedOption}
                onChange={handleChange}
              >
                <option value="">Select Type</option>
                {deploymentScenarios.map(scenario => (
                  <option key={scenario.name} value={scenario.name}>{scenario.name}</option>
                ))}
              </select>
            </div>
            <div className="flex justify-center">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                onClick={handleDeploy}
              >
                Deploy
              </button>
            </div>
          </div>
        </Popup>
      )}
      {rectangleBounds && (
        <Rectangle bounds={rectangleBounds.bounds} color={rectangleBounds.color} />
      )}
    </>
  );
}

const deploymentData: DeploymentData = {
  [BaseName.RISALEWALA]: [ // Deployments for Risalewala Airbase
    { aircraftId: AircraftId.F7, quantityDeployed: 12 },
    // { aircraftId: AircraftId.K8, quantityDeployed: 6 }
  ],
  [BaseName.LAHORE]: [ // Deployments for Lahore Airbase
    { aircraftId: AircraftId.F7, quantityDeployed: 4 }
  ],
  [BaseName.VIHARI]: [ // Deployments for Vihari Airbase
    { aircraftId: AircraftId.F7PG, quantityDeployed: 6 },
    //
  ],
  [BaseName.MULTAN]: [ // Deployments for Multan Airbase
    { aircraftId: AircraftId.MIRAGE, quantityDeployed: 11 },
    { aircraftId: AircraftId.F7, quantityDeployed: 10 }
  ],
  [BaseName.RAJANPUR]: [ // Deployments for Rajanpur Airbase
    { aircraftId: AircraftId.JF17, quantityDeployed: 13 }
  ],
  [BaseName.RAHIM_YAR_KHAN]: [ // Deployments for RYK Airbase
    { aircraftId: AircraftId.F7PG, quantityDeployed: 8 }
  ],
  [BaseName.SARGODHA]: [ // Deployments for Sargodha Airbase
    { aircraftId: AircraftId.F16, quantityDeployed: 25 },
    { aircraftId: AircraftId.F7, quantityDeployed: 6 }
  ],
  [BaseName.RAFIQUI]: [ // Deployments for Rafiqy Airbase
    { aircraftId: AircraftId.MIRAGE, quantityDeployed: 9 },
    { aircraftId: AircraftId.F7, quantityDeployed: 10 },
    { aircraftId: AircraftId.F7PG, quantityDeployed: 6 }
  ]
  // Add more airbases and deployments as needed
};


// Define custom divIcon with icon and text
const textIcon = (name: string) => L.divIcon({
  className: 'text-icon',
  html: `<div><img src="assets/images/ad/icons/strella.png" alt="icon" style="width: 24px; height: 24px; margin-right: 5px;" />${name}<br/>PAF Base</div>`, // Base name and text you want to display
});



const strelaIcon = L.icon({
  iconUrl: require('assets/images/ad/icons/strella.png'),
  shadowUrl: require('assets/images/ad/icons/strella.png'),
  iconSize: [24, 24],  // Width and height of the icon
  iconAnchor: [5, 5], // Anchor point of the icon (relative to top left)
  popupAnchor: [-3, -5], // Anchor point for popups (relative to the icon)
});

const f7pIcon = L.icon({
  iconUrl: require('assets/images/ac/icons/f7p.png'),

  iconSize: [32, 32],  // Width and height of the icon
  iconAnchor: [5, 5], // Anchor point of the icon (relative to top left)
  popupAnchor: [-3, -5], // Anchor point for popups (relative to the icon)
});

const f7pgIcon = L.icon({
  iconUrl: require('assets/images/ac/icons/f7pg.png'),

  iconSize: [32, 32],  // Width and height of the icon
  iconAnchor: [5, 5], // Anchor point of the icon (relative to top left)
  popupAnchor: [-3, -5], // Anchor point for popups (relative to the icon)
});

const f16cdIcon = L.icon({
  iconUrl: require('assets/images/ac/icons/f16cd.png'),

  iconSize: [32, 32],  // Width and height of the icon
  iconAnchor: [5, 5], // Anchor point of the icon (relative to top left)
  popupAnchor: [-3, -5], // Anchor point for popups (relative to the icon)
});

//f16mlu
const f16mluIcon = L.icon({
  iconUrl: require('assets/images/ac/icons/f16mlu.png'),

  iconSize: [32, 32],  // Width and height of the icon
  iconAnchor: [5, 5], // Anchor point of the icon (relative to top left)
  popupAnchor: [-3, -5], // Anchor point for popups (relative to the icon)
})

const shilkaIcon = L.icon({
  iconUrl: require('assets/images/ad/icons/shillka.png'),
  shadowUrl: require('assets/images/ad/icons/shillka.png'),
  iconSize: [24, 24],  // Width and height of the icon
  iconAnchor: [5, 5], // Anchor point of the icon (relative to top left)
  popupAnchor: [-3, -5], // Anchor point for popups (relative to the icon)
});
//j17
const j17Icon = L.icon({
  iconUrl: require('assets/images/ac/icons/j17.png'),

  iconSize: [32, 32],  // Width and height of the icon
  iconAnchor: [5, 5], // Anchor point of the icon (relative to top left)
  popupAnchor: [-3, -5], // Anchor point for popups (relative to the icon)
});
//mir34
const mir34Icon = L.icon({
  iconUrl: require('assets/images/ac/icons/mir34.png'),

  iconSize: [32, 32],  // Width and height of the icon
  iconAnchor: [5, 5], // Anchor point of the icon (relative to top left)
  popupAnchor: [-3, -5], // Anchor point for popups (relative to the icon)
});

const baseIcon = L.icon({
  iconUrl: require('assets/images/ad/icons/base.png'),

  iconSize: [24, 24],  // Width and height of the icon
  iconAnchor: [5, 5], // Anchor point of the icon (relative to top left)
  popupAnchor: [-3, -5], // Anchor point for popups (relative to the icon)
});


const airbases: Airbase[] = [
  { id: BaseName.RISALEWALA, name: "Risalewala Air Force Base", latitude: 31.3768, longitude: 73.0024, icon: f7pIcon,region:Region.CAC, type: BaseType.FIGHTER },
  { id: BaseName.RAJANPUR, name: "Rajanpur", latitude: 29.2636, longitude: 70.18755, icon: f7pIcon ,region:Region.SAC, type: BaseType.FIGHTER },
  { id: BaseName.RAHIM_YAR_KHAN, name: "Rahim Yar Khan", latitude: 28.399513868745814, longitude: 70.28614584523605, icon: f7pgIcon ,region:Region.SAC, type: BaseType.FIGHTER },
  { id: BaseName.LAHORE, name: "Lahore", latitude: 31.65085897593921, longitude: 74.3992232668358, icon: f7pIcon ,region:Region.CAC, type: BaseType.FIGHTER },
  { id: BaseName.VIHARI, name: "Vihari", latitude: 33.69082319449518, longitude: 71.41111544305879, icon: f7pgIcon ,region:Region.CAC, type: BaseType.FIGHTER },
  { id: BaseName.MULTAN, name: "Multan", latitude: 30.19639530017037, longitude: 71.42365501060998, icon: mir34Icon ,region:Region.CAC, type: BaseType.FIGHTER },
  { id: BaseName.SARGODHA, name: "Sargodha", latitude: 32.03992520146076, longitude: 72.68532966543607, icon: f16cdIcon,region:Region.CAC, type: BaseType.FIGHTER  },
  { id: BaseName.RAFIQUI, name: "Rafiqui", latitude: 30.755499252518163, longitude: 72.28025953849955, icon: mir34Icon ,region:Region.CAC, type: BaseType.FIGHTER }
];

// Define aircrafts
const aircrafts: Aircraft[] = [
  {
    id: AircraftId.JF17,
    name: "JF-17",
    icon: j17Icon,
    description: "The JF-17 Thunder, also designated as FC-1 Xiaolong, is a lightweight, single-engine, multi-role combat aircraft developed jointly by the Pakistan Aeronautical Complex (PAC) and the Chengdu Aircraft Corporation (CAC) of China.",
    range: 2000, // Example range in kilometers
    maxSpeed: 1900 // Example max speed in kilometers per hour
  },
  {
    id: AircraftId.F16,
    name: "F-16",
    icon: f16cdIcon,
    description: "The General Dynamics F-16 Fighting Falcon is a single-engine supersonic multirole fighter aircraft originally developed by General Dynamics for the United States Air Force (USAF).",
    range: 3700, // Example range in kilometers
    maxSpeed: 2414 // Example max speed in kilometers per hour
  },

  {
    id: AircraftId.MIRAGE,
    name: "Mirage 3/5",
    icon: mir34Icon,
    description: "The Dassault Mirage III is a family of single-seat, single-engine, fighter aircraft developed and manufactured by French aircraft company Dassault Aviation.",
    range: 3300, // Example range in kilometers
    maxSpeed: 2360 // Example max speed in kilometers per hour
  },
  {
    id: AircraftId.F7,
    name: "F-7",
    icon: f7pIcon,
    description: "The Chengdu J-7 is a People's Republic of China license-built version of the Soviet Mikoyan-Gurevich MiG-21.",
    range: 1250, // Example range in kilometers
    maxSpeed: 2170 // Example max speed in kilometers per hour
  },
  {
    id: AircraftId.F7PG,
    name: "F-7PG",
    icon: f7pgIcon,
    description: "The Chengdu J-7 is a People's Republic of China license-built version of the Soviet Mikoyan-Gurevich MiG-21.",
    range: 1250, // Example range in kilometers
    maxSpeed: 2170 // Example max speed in kilometers per hour
  },
  // Add more aircrafts as needed
];

interface AirDefenseAsset {
  name: string;
  comm_call_sign: string;
  location: Location;
  operational_status: string;
  attack_radius: number; // in kilometers
  color: string; // color for the asset
}

interface AirDefenseData {
  Mahajan_Firing_Range: {
    location: Location;
  };
  air_defense_assets: AirDefenseAsset[];
}

interface ADSitrepRealtimeProps {
  lat: number;
  lng: number;
  zoom: number;
  title: string;
}

interface AttackPackage {
  baseName: string;
  aircraftId: string;
  quantity: number;
  payload: string;
  lat: number;
  lng: number;
  speed: number;
}

const Configurator: React.FC<{ showBlastRadius: boolean; toggleBlastRadius: () => void }> = ({
  showBlastRadius,
  toggleBlastRadius
}) => {
  return (
    <div className="configurator flex items-center">
      <Switch
        checked={showBlastRadius}

        onChange={toggleBlastRadius}
        crossOrigin="example-origin"
        className="mr-2"
      />
      <p className="text-gray-800 text-sm">Highlight Bases</p>
    </div>
  );
};





const GeneratePopupContent: React.FC<{ baseName: string; deployments: Deployment[] }> = ({ baseName, deployments }) => {
  
  //const { sharedData} = useContext(DataContext)!;
  //const mapER = useMap();
  const [attackResult, setAttackResult] = useState<string | null>(null);

  const handleLaunchAttack = () => {
   
    deployments.forEach((deployment) => {
      const aircraftId = deployment.aircraftId;
      const checkbox = document.getElementById(`checkbox-${aircraftId}`) as HTMLInputElement | null;
      if (checkbox && checkbox.checked) {
        interface AttackPackage {
          baseName: string;
          aircraftId: string;
          quantity: number;
          payload: string;
          speed: number;
          lat: number;
          lng: number;
        }

        const quantityElement = document.getElementById(`${aircraftId}-quantity`) as HTMLSelectElement | null;
        const payloadElement = document.getElementById(`${aircraftId}-payload`) as HTMLSelectElement | null;
        if (quantityElement && payloadElement) {
          const quantity = parseInt(quantityElement.value);
          const payload = payloadElement.value;
          selectedAircraftData.push({
            baseName,
            aircraftId,
            quantity,
            payload,
            speed: aircrafts.find(aircraft => aircraft.id === aircraftId)?.maxSpeed || 0,
            lat: airbases.find(base => base.name === baseName)?.latitude || 0,
            lng: airbases.find(base => base.name === baseName)?.longitude || 0,
          });
        }
        toast.info(`Added ${aircraftId} from ${baseName} to simulation`);
      }
    });

    //Get total number of aircraft deployed
    const totalAircraftDeployed = selectedAircraftData.reduce((total, aircraft) => total + aircraft.quantity, 0);
    console.log('Selected Aircraft Data:', selectedAircraftData);
    //print  GlobalVariable on console
    console.log('Deployed data :', deployedData);

    deployedData.number_of_aircraft = totalAircraftDeployed;

    //print the deployment data on console
    console.log('Sim Dta :', deployedData);

    // //from deployed .data get width and height of deployment area
    // //console.log('Width:', deployedData.width);
    // //call the function to calculate survivability with above data
    // const inputs: AirRaidInputs = {
    //   numberOfAircraft: totalAircraftDeployed,
    //   aircraftAccuracy: 25,
    //   squareKilometersCoveredPerAttack: 50,
    //   totalNumberOfArmoredVehicles: 200,
    //   deploymentArea: {
    //     width: 10,
    //     height: 10,
    //   },
    //   kKillRatio: 20,
    //   mKillRatio: 30,
    //   adBoostFactor: 5,
    //   lat: 0,
    //   lon: 0,
    // };
    // const calculator = new TankSurvivabilityCalculator(deployedData);
    // const result = calculator.calculateSurvivability();
    // //print the result on console
    // console.log("Overall Survivability:", result.overallSurvivability.toFixed(2) + "%");
    // console.log("Overall Survivability:", result.overallSurvivability.toFixed(2) + "%");
    // console.log("P-Kill (Totally Destroyed):", result.kKill.toFixed(2) + "%");
    // console.log("M-Kill (Mobility Impacted):", result.mKill.toFixed(2) + "%");
    // console.log("Survivability with AD:", result.survivabilityWithAD.toFixed(2) + "%");
    // //print other data on console

    // //Display the result on the map
    // // Create a div element to display the result
    // // const resultElement = document.createElement('div');
    // const simulatedResult11 = `
    //   <h3 class="text-lg font-semibold mb-2">Stimulated Attck from ${baseName} Base</h3>
    //   <p class="text-sm mb-4 text-gray-600">Survivability Result</p>
    //   <ul>
    //   <li>Total Aircrafts : ${deployedData.numberOfAircraft}</li>
    //     <li>Overall Survivability: ${result.overallSurvivability.toFixed(2)}%</li>
    //     <li>P-Kill (Totally Destroyed): ${result.kKill.toFixed(2)}%</li>
    //     <li>M-Kill (Mobility Impacted): ${result.mKill.toFixed(2)}%</li>
    //     <li>Survivability with AD: ${result.survivabilityWithAD.toFixed(2)}%</li>
    //   </ul>
    // `;

    // // const simulatedResult = "Simulated attack result"; // Replace with your simulation logic
    // setAttackResult(simulatedResult11);

    //create a normal dialog box with above data

    // alert(`Overall Survivability: ${result.overallSurvivability.toFixed(2)}%\nP-Kill (Totally Destroyed): ${result.pKill.toFixed(2)}%\nM-Kill (Mobility Impacted): ${result.mKill.toFixed(2)}%\nSurvivability with AD: ${result.survivabilityWithAD.toFixed(2)}%`);



    // // Create a popup with the resultElement




    // Create a popup with the resultElement
    // const popup = L.popup()
    //   .setLatLng([deployedData.lat, deployedData.lon])
    //   .setContent(resultElement)
    //   .openOn(mapER);
    // // Add the popup to the map
    // //map.addLayer(popup);

    //update popoup content






    //print shared data on console
    // check if shared data is null
    // if (sharedData) {
    //   console.log('Shared Data:', sharedData);
    // }else{
    //   console.log('Shared Data is null');
    // }

    // Add logic here to handle launching the attack using selectedAircraftData
  };




  const getAircraftIcon = (aircraftId: string) => {
    switch (aircraftId) {
      case AircraftId.F7:
        return f7pIcon;
      case AircraftId.F7PG:
        return f7pgIcon;
      case AircraftId.F16:
        return f16cdIcon;
      case AircraftId.JF17:
        return j17Icon;
      case AircraftId.MIRAGE:
        return mir34Icon;


      // Add cases for other aircraft types if needed
      default:
        return f16cdIcon;
    }
  };


  return (
    
    <div className="max-w-md">
      <ToastContainer/>
      <h3 className="text-lg font-semibold mb-2">{baseName} PAF Base</h3>
      <p className="text-sm mb-4 text-gray-600">Assets Deployed</p>
      <ul className="flex justify-center items-center flex-wrap mb-4">
        {deployments.map((deployment, index) => (
          <li key={index} className="flex items-center mr-4 mb-2">
            <input
              type="checkbox"
              id={`checkbox-${deployment.aircraftId}`} // Use aircraftId as the unique identifier
              className="mr-2"
            // You can handle checkbox selection logic here
            />
            {getAircraftIcon(deployment.aircraftId) && (
              <img
                src={getAircraftIcon(deployment.aircraftId).options.iconUrl}
                alt={deployment.aircraftId}
                className="w-30 h-30 mr-1"
              />
            )}
            <span className="text-sm">{deployment.aircraftId} : </span>
            <select id={`${deployment.aircraftId}-quantity`} className="mx-2" defaultValue={deployment.quantityDeployed}>
              {[...Array(deployment.quantityDeployed + 1)].map((_, num) => (
                <option key={num} value={num}>{num}</option>
              ))}
            </select>
            <select id={`${deployment.aircraftId}-payload`} className="mx-2" defaultValue="Air To Ground">
              <option value="Air To Ground">Air To Ground</option>
              <option value="Air to Air">Air to Air</option>
              <option value="Air & Ground">Air & Ground</option>
            </select>
          </li>
        ))}
      </ul>
      <div className="flex justify-center">
        <button onClick={handleLaunchAttack} className="bg-red-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400">
          Add To Simulation
        </button>
      </div>

      {/* {attackResult && (
        <div>
          <h3 className="text-lg font-semibold mt-4">Attack Result</h3>

          <div dangerouslySetInnerHTML={{ __html: attackResult }} />
        </div>
      )} */}
    </div>
  );
};


const MapWithAirbases = () => {
  const [selectedType, setSelectedType] = useState('all');
  const [selectedRegion, setSelectedRegion] = useState('all');

  const filteredAirbases = airbases.filter(airbase => {
    return (selectedType === 'all' || airbase.type === selectedType) &&
           (selectedRegion === 'all' || airbase.region === selectedRegion);
  });

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <label>
          Type:
          <select value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
            <option value="all">All</option>
            <option value={BaseType.UAV}>UAV</option>
            <option value={BaseType.HELI}>Attack Helicopter</option>
            <option value={BaseType.FIGHTER}>Fighter Jets</option>
            <option value={BaseType.FIGHTER_HELI}>Fighter Jets & Attack Helicopter</option>
            <option value={BaseType.BOMBER}>Bomber</option>
          </select>
        </label>
        <label style={{ marginLeft: '10px' }}>
          Region:
          <select value={selectedRegion} onChange={(e) => setSelectedRegion(e.target.value)}>
            <option value="all">All</option>
            <option value={Region.NAC}>Peshawar (NAC)</option>
            <option value={Region.CAC}>Lahore (CAC)</option>
            <option value={Region.SAC}>Sher-e-Faisal (SAC)</option>
            <option value={Region.ADC}>Chaklala (ADC)</option>
            <option value={Region.AFSC}>Islamabad (AFSC)</option>
          </select>
        </label>
      </div>
      <MapContainer center={[30.3753, 69.3451]} zoom={6} style={{ height: "800px", width: "100%" }}>
        <LayersControl position="topleft">
          {/* Base Layers */}
          <LayersControl.BaseLayer checked name="OpenStreetMap">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
            />
          </LayersControl.BaseLayer>

          Overlay Layers
          <LayersControl.Overlay checked name="Airbases">
            <div>
              {filteredAirbases.map((airbase) => (
                <Marker position={[airbase.latitude, airbase.longitude]} icon={baseIcon} key={airbase.id}>
                  <Popup>
                  <GeneratePopupContent baseName={airbase.name} deployments={deploymentData[airbase.id] || []} />
                  </Popup>
                </Marker>
              ))}
            </div>
          </LayersControl.Overlay>

          {/* Add more overlay layers as needed */}
        </LayersControl>
      </MapContainer>
    </div>
  );
};


const MapStyleSelector: React.FC<{ onSelect: (style: string) => void }> = ({ onSelect }) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onSelect(event.target.value);
  };

  return (
    <div className="map-style-selector flex items-center">
      <p className="text-gray-800 text-sm mr-2">Map Style</p>
      <select onChange={handleChange} className="border border-gray-300 rounded-md px-2 py-1">
        <option value="normal">Normal</option>
        <option value="topographic">Topographic</option>
        <option value="satellite">Satellite</option>
      </select>
    </div>
  );
};
const Deploy: React.FC<{ onDeploy: (data: any) => void }> = ({ onDeploy }) => {
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [type, setType] = useState('');


  const [overlayBounds, setOverlayBounds] = useState<[number, number][] | null>(null);



  const handleDeploy = () => {
    // Validate latitude, longitude, and type before deploying
    if (latitude && longitude && type) {
      // Generate overlay bounds
      const lat = parseFloat(latitude);
      const lng = parseFloat(longitude);
      const halfWidth = 18 / 2 / 111.32; // 1 degree of latitude is approximately 111.32 kilometers
      const halfHeight = 24 / 2 / (111.32 * Math.cos((lat * Math.PI) / 180)); // 1 degree of longitude varies with latitude
      const bounds: [number, number][] = [
        [lat + halfHeight, lng - halfWidth], // Top-left corner
        [lat - halfHeight, lng + halfWidth], // Bottom-right corner
      ];
      setOverlayBounds(bounds);

      // Call onDeploy with data
      onDeploy({ latitude, longitude, type });

      // Clear input fields after deploying
      setLatitude('');
      setLongitude('');
      setType('');
    } else {
      alert('Please enter latitude, longitude, and type');
    }
  };

  return (
    <div style={{ backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px' }}>
      <input
        type="text"
        placeholder="Latitude"
        value={latitude}
        onChange={(e) => setLatitude(e.target.value)}
      />
      <input
        type="text"
        placeholder="Longitude"
        value={longitude}
        onChange={(e) => setLongitude(e.target.value)}
      />
      <select value={type} onChange={(e) => setType(e.target.value)}>
        <option value="">Select Type</option>
        <option value="CSA">CSA</option>
        <option value="Bridge">Bridge</option>
        <option value="Lean on">Lean on</option>
        <option value="Pivot">Pivot</option>
      </select>
      <button style={{ backgroundColor: 'green', color: 'white', border: 'none', padding: '5px 10px', borderRadius: '3px', cursor: 'pointer' }} onClick={handleDeploy}>Deploy</button>

      {/* Render the overlay rectangle if bounds exist */}
      {overlayBounds && (
        <Rectangle bounds={overlayBounds} color="blue" />
      )}
    </div>
  );
};
interface AirbaseControlProps {
  position: 'topright' | 'topleft' | 'bottomright' | 'bottomleft';
}

const AirbaseControl: React.FC<AirbaseControlProps> = ({ position }) => {
  const map = useMap();
  const [selectedBase, setSelectedBase] = useState<Airbase | null>(null);
  const [popup, setPopup] = useState<L.Popup | null>(null);

  const handleRadioChange = useCallback((base: Airbase) => {
    setSelectedBase(base);
  }, []);

  useEffect(() => {
    if (selectedBase) {
      const popupContent = `
        <div>
          <h3>${selectedBase.name}</h3>
          <p>Click on base to Start</p>
        </div>
      `;
      const newPopup = L.popup()
        .setLatLng([selectedBase.latitude, selectedBase.longitude])
        .setContent(popupContent)
        .openOn(map);
      setPopup(newPopup);
    }
  }, [map, selectedBase]);

  useEffect(() => {
    if (popup) {
      map.on('popupclose', () => {
        setPopup(null);
      });
    }
  }, [map, popup]);

  useEffect(() => {
    const AirbaseControl = L.Control.extend({
      onAdd: (map: L.Map) => {
        const container: HTMLElement = L.DomUtil.create('div', 'leaflet-bar leaflet-control custom-control');
        container.style.backgroundColor = '#fff';
        container.style.padding = '10px';
        container.style.boxShadow = '0 2px 6px rgba(0,0,0,0.3)';

        airbases.forEach((base: Airbase) => {
          const label: HTMLLabelElement = L.DomUtil.create('label', '', container);
          label.style.display = 'block';
          label.style.marginBottom = '5px';

          const radio: HTMLInputElement = L.DomUtil.create('input', '', label) as HTMLInputElement;
          radio.type = 'radio';
          radio.name = 'airbase';
          radio.value = base.id;
          radio.checked = selectedBase?.id === base.id;
          radio.onchange = () => handleRadioChange(base);

          const text: HTMLSpanElement = L.DomUtil.create('span', '', label);
          text.innerText = ` ${base.name}`;
        });

        return container;
      }
    });

    const control = new AirbaseControl({ position });
    control.addTo(map);

    return () => {
      map.removeControl(control);
    };
  }, [map, position, handleRadioChange, selectedBase]);

  return null;
};


const ADSitrepRealtime: React.FC<ADSitrepRealtimeProps> = ({ lat, lng, zoom, title }) => {
  const [showBlastRadius, setShowBlastRadius] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<[number, number] | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [sharedData, setSharedData] = useState<Record<string, any>>({});
  const [deploymentStarted, setDeploymentStarted] = useState(false);
  const [travelTime, setTravelTime] = useState(0);
  const [lines, setLines] = useState<L.LatLng[][]>([]);
  

    // NEW: State for filtering airbases
    const [selectedType, setSelectedType] = useState('all');
    const [selectedRegion, setSelectedRegion] = useState('all');

    const [selectedBase, setSelectedBase] = useState<Airbase | null>(null);

    

  const mapRef = useRef<LeafletMap | null>(null);
  const popupRef = useRef<L.Popup | null>(null);
  
    // NEW: Filtered airbases logic
    const filteredAirbases = airbases.filter(airbase => {
      return (selectedType === 'all' || airbase.type === selectedType) &&
             (selectedRegion === 'all' || airbase.region === selectedRegion);
    });
  //lines

  const handleDeploy = (data: any) => {
    // Handle deployment logic here
    console.log('Deploying:', data);
    // Add logic to deploy based on the data received
  };
  const toggleBlastRadius = () => {
    setShowBlastRadius((prev) => !prev);
  };

  const handleContextMenu = (event: L.LeafletMouseEvent) => {
    event.originalEvent.preventDefault(); // Prevent default browser context menu
    setContextMenuPosition([event.latlng.lat, event.latlng.lng]);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };
  const [mapStyle, setMapStyle] = useState('normal');


  const handleMapStyleChange = (style: string) => {
    setMapStyle(style);
  };


  const LocationFinderDummy = () => {
    const map = useMapEvents({
      click(e) {
        console.log(e.latlng);
      },
    });
    return null;
  };

  const handleLineCreated = (e: any) => {
    const { layerType, layer } = e;
    if (layerType === 'polyline') {
      const line = layer.getLatLngs() as L.LatLng[];
      // Add the line to the state
      airCorridorLine = line;

      let newLine = line;
// check selectedAircraftData is empty

if (!selectedAircraftData || Object.keys(selectedAircraftData).length === 0) {
  console.log('selectedAircraftData is empty');
}else{
  if (selectedAircraftData[0].lat !== 0) {
    console.log(" Added Base  coordinates to Start  .... ")
    newLine = [new L.LatLng(selectedAircraftData[0].lat, selectedAircraftData[0].lng), ...newLine];
  }
}

      
      if (deployedData.lat !== 0 && deployedData.lon !== 0) {
        console.log(" Added deployement coordinates to end .... ")
        newLine = [...newLine, new L.LatLng(deployedData.lat, deployedData.lon)];
      }

      airCorridorLine = newLine;
    console.log('Setting Line:', airCorridorLine);
      //setLines((prevLines) => [...prevLines, line]);
      console.log('Setting Line:', line);

    }
  };


  const count = useSelector((state: RootState) => state.counter.count);
  const dispatch = useDispatch();


  return (
    
    // <DataContextProvider value={{ sharedData, setSharedData, deploymentStarted, setDeploymentStarted, travelTime, setTravelTime, lines, setLines }}>
      <div className="order-11 col-span-12 2xl:order-1 card 2xl:col-span-12">
       

        
        <div className="card">
          <div className="card-body">
            <div className="flex justify-between items-center">
              <h6 className="mb-4 text-gray-800 text-15 dark:text-white">{title}</h6>
              <Switch
                checked={showBlastRadius}
                color="blue"
                onChange={toggleBlastRadius}
                crossOrigin="example-origin" // Add crossOrigin prop
              />
              <MapStyleSelector onSelect={handleMapStyleChange} />
              <Configurator showBlastRadius={showBlastRadius} toggleBlastRadius={toggleBlastRadius} />
       </div>

   {/* NEW: Filter dropdowns */}
 


            <MapContainer center={[lat, lng]} zoom={zoom} className="leaflet-map" style={{ height: "800px" }} scrollWheelZoom={true} >
            {/* <LayersControl position="topleft"> */}
            {/* <MapWithAirbases /> */}

              <DeploymentComponent />


              <TileLayer
                url={mapStyle === 'normal' ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" :
                  mapStyle === 'topographic' ? "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png" :
                    "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"}
                attribution={mapStyle === 'normal' ? '&copy; <a href="https://www.openstreetmap.org/copyright">OSM</a> Junlylabs contributors' :
                  mapStyle === 'topographic' ? '&copy; <a href="https://opentopomap.org/about#verwendung">OpenTopoMap</a> contributors' :
                    'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'}
              />

            {filteredAirbases.map((airbase, index) => (
                <LayersControl.Overlay
                  key={index}
                  name={airbase.name}
                  checked={selectedBase?.id === airbase.id}
                >
                  <React.Fragment>
                    <Marker position={[airbase.latitude, airbase.longitude]} icon={baseIcon}>
                      <Popup ref={popupRef}>
                      <GeneratePopupContent baseName={airbase.name} deployments={deploymentData[airbase.id] || []} />
                      </Popup>
                    </Marker>
                   
                  </React.Fragment>
                </LayersControl.Overlay>
              ))}

            

            <FeatureGroup>
                <EditControl
                  position='topright'
                  onCreated={handleLineCreated}
                  draw={{
                    rectangle: false,
                    circle: false,
                    polygon: false,
                    circlemarker: false,
                    marker: false,
                    polyline: true,
                  }}
                />
              </FeatureGroup>



    

              {airbases.map((airbase: Airbase, index: number) => (
                <React.Fragment key={index}>
                   <Marker position={[airbase.latitude, airbase.longitude]} icon={baseIcon}>

              

                    <Popup>

                     
                    <GeneratePopupContent baseName={airbase.name} deployments={deploymentData[airbase.id] || []} />

                    </Popup>
                  </Marker>
                  {showBlastRadius && (
                    <Circle center={[airbase.latitude, airbase.longitude]} radius={15000} color="red" fillOpacity={0.5} />
                  )}
                </React.Fragment>
              ))}
               {/* </LayersControl> */}
               <AirbaseControl position="topleft" />
            </MapContainer>
          </div>
        </div>
      </div>
    // </DataContext.Provider>
  );
};

// export default ADSitrepRealtime;


// Wrap the ADSitrepRealtime component with DataContextProvider

// const ADSitrepRealtime: React.FC<ADSitrepRealtimeProps> = ({ lat, lng, zoom, title }) => {
//   const [showBlastRadius, setShowBlastRadius] = useState(false);
//   const [contextMenuPosition, setContextMenuPosition] = useState<[number, number] | null>(null);
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [sharedData, setSharedData] = useState<Record<string, any>>({});
//   const [deploymentStarted, setDeploymentStarted] = useState(false);
//   const [travelTime, setTravelTime] = useState(0);
//   const [lines, setLines] = useState<L.LatLng[][]>([]);

//   const [selectedType, setSelectedType] = useState('all');
//   const [selectedRegion, setSelectedRegion] = useState('all');
//   const [selectedBase, setSelectedBase] = useState<Airbase | null>(null);

//   const mapRef = useRef<LeafletMap | null>(null);
//   const popupRef = useRef<L.Popup | null>(null);

//   const filteredAirbases = airbases.filter(airbase => {
//     return (selectedType === 'all' || airbase.type === selectedType) &&
//            (selectedRegion === 'all' || airbase.region === selectedRegion);
//   });

//   const handleDeploy = (data: any) => {
//     console.log('Deploying:', data);
//   };

//   const toggleBlastRadius = () => {
//     setShowBlastRadius((prev) => !prev);
//   };

//   const handleContextMenu = (event: L.LeafletMouseEvent) => {
//     event.originalEvent.preventDefault();
//     setContextMenuPosition([event.latlng.lat, event.latlng.lng]);
//     setIsMenuOpen(true);
//   };

//   const handleMenuClose = () => {
//     setIsMenuOpen(false);
//   };

//   const [mapStyle, setMapStyle] = useState('normal');

//   const handleMapStyleChange = (style: string) => {
//     setMapStyle(style);
//   };

//   const LocationFinderDummy = () => {
//     const map = useMapEvents({
//       click(e) {
//         console.log(e.latlng);
//       },
//     });
//     return null;
//   };

//   const handleLineCreated = (e: any) => {
//     const { layerType, layer } = e;
//     if (layerType === 'polyline') {
//       const line = layer.getLatLngs() as L.LatLng[];
//       airCorridorLine = line;
//       console.log('Setting Line:', line);
//     }
//   };

//   const count = useSelector((state: RootState) => state.counter.count);
//   const dispatch = useDispatch();

//   const handleBaseSelection = (airbase: Airbase) => {
//     setSelectedBase(airbase);
//     if (mapRef.current) {
//       mapRef.current.setView([airbase.latitude, airbase.longitude], zoom);
//       if (popupRef.current) {
//         popupRef.current.setLatLng([airbase.latitude, airbase.longitude]).openOn(mapRef.current);
//       }
//     }
//   };

//   useEffect(() => {
//     if (selectedBase && mapRef.current && popupRef.current) {
//       popupRef.current.setLatLng([selectedBase.latitude, selectedBase.longitude]).openOn(mapRef.current);
//     }
//   }, [selectedBase]);

//   return (
//     <div className="order-11 col-span-12 2xl:order-1 card 2xl:col-span-12">
//       <div className="card">
//         <div className="card-body">
//           <div className="flex justify-between items-center">
//             <h6 className="mb-4 text-gray-800 text-15 dark:text-white">{title}</h6>
//             <Switch
//               checked={showBlastRadius}
//               color="blue"
//               onChange={toggleBlastRadius}
//               crossOrigin="example-origin"
//             />
//             <MapStyleSelector onSelect={handleMapStyleChange} />
//             <Configurator showBlastRadius={showBlastRadius} toggleBlastRadius={toggleBlastRadius} />
//           </div>

//           <MapContainer
//             center={[lat, lng]}
//             zoom={zoom}
//             className="leaflet-map"
//             style={{ height: "800px" }}
//             scrollWheelZoom={true}
//             // whenReady={(event) => { mapRef.current = event.target; }}
//           >
//             <LayersControl position="topleft">
//               <LayersControl.BaseLayer checked name="OpenStreetMap">
//                 <TileLayer
//                   url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//                   attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
//                 />
//               </LayersControl.BaseLayer>
//               <LayersControl.BaseLayer name="Satellite">
//                 <TileLayer
//                   url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
//                   attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
//                 />
//               </LayersControl.BaseLayer>

//               <LayersControl.Overlay name="Filters">
//                 <div style={{ marginBottom: '10px', padding: '10px', backgroundColor: '#f0f0f0', borderRadius: '5px' }}>
//                   <label>
//                     Type:
//                     <select value={selectedType} onChange={(e) => setSelectedType(e.target.value)} style={{ marginLeft: '10px', marginRight: '20px' }}>
//                       <option value="all">All</option>
//                       <option value={BaseType.UAV}>UAV</option>
//                       <option value={BaseType.HELI}>Attack Helicopter</option>
//                       <option value={BaseType.FIGHTER}>Fighter Jets</option>
//                       <option value={BaseType.FIGHTER_HELI}>Fighter Jets & Attack Helicopter</option>
//                       <option value={BaseType.BOMBER}>Bomber</option>
//                     </select>
//                   </label>
//                   <label style={{ marginLeft: '10px' }}>
//                     Region:
//                     <select value={selectedRegion} onChange={(e) => setSelectedRegion(e.target.value)} style={{ marginLeft: '10px' }}>
//                       <option value="all">All</option>
//                       <option value={Region.NAC}>Peshawar (NAC)</option>
//                       <option value={Region.CAC}>Lahore (CAC)</option>
//                       <option value={Region.SAC}>Sher-e-Faisal (SAC)</option>
//                       <option value={Region.ADC}>Chaklala (ADC)</option>
//                       <option value={Region.AFSC}>Islamabad (AFSC)</option>
//                     </select>
//                   </label>
//                 </div>
//               </LayersControl.Overlay>

//               {filteredAirbases.map((airbase, index) => (
//                 <LayersControl.Overlay
//                   key={index}
//                   name={airbase.name}
//                   checked={selectedBase?.id === airbase.id}
//                 >
//                   <React.Fragment>
//                     <Marker position={[airbase.latitude, airbase.longitude]} icon={baseIcon}>
//                       <Popup ref={popupRef}>
//                         {GeneratePopupContent(airbase.name, deploymentData[airbase.id] || [])}
//                       </Popup>
//                     </Marker>
//                     {showBlastRadius && (
//                       <Circle center={[airbase.latitude, airbase.longitude]} radius={15000} color="red" fillOpacity={0.5} />
//                     )}
//                   </React.Fragment>
//                 </LayersControl.Overlay>
//               ))}

//               <FeatureGroup>
//                 <EditControl
//                   position='topright'
//                   onCreated={handleLineCreated}
//                   draw={{
//                     rectangle: false,
//                     circle: false,
//                     polygon: false,
//                     circlemarker: false,
//                     marker: false,
//                     polyline: true,
//                   }}
//                 />
//               </FeatureGroup>
//             </LayersControl>
//           </MapContainer>

//           {selectedBase && (
//             <Popup
//               ref={popupRef}
//               position={[selectedBase.latitude, selectedBase.longitude]}
//               closeButton={true}
//               autoClose={false}
//             >
//               {GeneratePopupContent(selectedBase.name, deploymentData[selectedBase.id] || [])}
//             </Popup>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };



const WrappedADSitrepRealtime: React.FC<ADSitrepRealtimeProps> = (props) => (
  <DataContextProvider>
    <ADSitrepRealtime {...props} />
  </DataContextProvider>
);

export default WrappedADSitrepRealtime;
